import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { formatNumbersToUnits } from '../../actions';
import { Link } from "react-scroll";
const ProfileSection = (props) => {
  return (
    <>
      {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.posts && props.postAnalysisDetails.posts_profile.posts.length ?
        <Row className="showcase-4-cols justify-content-center mb-2">
          {props.postAnalysisDetails.posts_profile.posts[0]
            ? <Col md="5">
              <img className="img-fluid" src={props.postAnalysisDetails.posts_profile.posts[0].image || ''} alt="userimg" />
            </Col> : null}
          {props.postAnalysisDetails.posts_profile.posts[1]
            ?
            <Col md="5">
              <img className="img-fluid" src={props.postAnalysisDetails.posts_profile.posts[1].image || ''} alt="userimg" />
            </Col>
            : null}
          {props.postAnalysisDetails.posts_profile.posts[2] || props.postAnalysisDetails.posts_profile.posts[3]
            ?

            <Col md="2">
              <Row>
                {props.postAnalysisDetails.posts_profile.posts[2]
                  ?
                  <Col md="12">
                    <img className="img-fluid" src={props.postAnalysisDetails.posts_profile.posts[2].image || ''} alt="userimg" />
                    <Link smooth={true} to="Posts" className="icon icon-camera"></Link>

                  </Col>
                  : null}
                {props.postAnalysisDetails.posts_profile.posts[3]
                  ?
                  <Col md="12">
                    <img className="img-fluid" src={props.postAnalysisDetails.posts_profile.posts[3].image || ''} alt="userimg" />
                  </Col>
                  : null}
              </Row>
            </Col>
            : null}
        </Row>
        : null}
      <Row className="justify-content-center">
        <Col md="8" className="d-flex justify-content-between flex-wrap">
          <Card className="card-content-custom my-2 flex-1 mx-2 m-width-150">
            <CardBody>
              <h3>{formatNumbersToUnits(props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.posts ? props.postAnalysisDetails.posts_profile.posts.length : 0)}</h3>
              <CardTitle className="mb-0">Posts Analyzed</CardTitle>
            </CardBody>
          </Card>
          <Card className="card-content-custom my-2 flex-1 mx-2 m-width-150">
            <CardBody>
              <h3>{formatNumbersToUnits(props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.creators ? props.postAnalysisDetails.posts_profile.creators.length : 0)}</h3>
              <CardTitle className="mb-0">Creators</CardTitle>
            </CardBody>
          </Card>
          <Card className="card-content-custom my-2 flex-1 mx-2 m-width-150">
            <CardBody>
              <h3>{formatNumbersToUnits(props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.likes)}</h3>
              <CardTitle className="mb-0">Likes</CardTitle>
            </CardBody>
          </Card>
          <Card className="card-content-custom my-2 flex-1 mx-2 m-width-150">
            <CardBody>
              <h3>{formatNumbersToUnits(props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.comments)}</h3>
              <CardTitle className="mb-0">Comments</CardTitle>
            </CardBody>
          </Card>
          <Card className="card-content-custom my-2 flex-1 mx-2 m-width-150">
            <CardBody>
              <h3>{formatNumbersToUnits(props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.potential_reach)}</h3>
              <CardTitle className="mb-0">Potential Reach</CardTitle>
            </CardBody>
          </Card>
          <Card className="card-content-custom my-2 flex-1 mx-2 m-width-150">
            <CardBody>
              <h3>{formatNumbersToUnits(props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.unique_audience)}</h3>
              <CardTitle className="mb-0">Unique Audience</CardTitle>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ProfileSection;
