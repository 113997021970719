import React from 'react';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import { formatNumbersToUnits } from '../../../actions';

const HasNoStory = props => {
  return (<div className="section">
    <>
      {props ?
        <>
          <div className="section-head text-center"><h2>Story Details</h2></div>
          <Row>
            <Col sm="12">
              <Card className="card-content-custom">
                {
                  props.is_private ?
                    <CardBody>This account is private. Unable to retrieve stories.</CardBody>
                    : (!props.has_stories ? <CardBody>This account currently has no stories to display.</CardBody> : null)
                }
              </Card>
            </Col>
            <Col sm="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{formatNumbersToUnits(props.estimated_reach)}</h2></div>
                  </div>
                  <CardTitle>Estimated Reach</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{formatNumbersToUnits(props.estimated_impressions)}</h2></div>
                  </div>
                  <CardTitle>Estimated Impressions</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{formatNumbersToUnits(props.back)}</h2></div>
                  </div>
                  <CardTitle>Estimated Backs / Rewatch</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{formatNumbersToUnits(props.forwards)}</h2></div>
                  </div>
                  <CardTitle>Estimated Forwards</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{formatNumbersToUnits(props.estimated_swipe_ups)}</h2></div>
                  </div>
                  <CardTitle>Estimated Swipe Ups</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{props.replies ? Math.round(props.replies) : 0}</h2></div>
                  </div>
                  <CardTitle>Estimated Replies</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{props.stories_per_day ? Math.round(props.stories_per_day): 0}</h2></div>
                  </div>
                  <CardTitle>Stories Per Day</CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col md="6">
              <Card className="card-content-custom">
                <CardBody>
                  <div className="country-text-wrap">
                    <div className="country-name pt-2"><h2>{formatNumbersToUnits(props.stories && props.stories.length === 0 ? 0 : null)}</h2></div>
                  </div>
                  <CardTitle>Current Number of Stories</CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </> : null}
    </>
  </div>)
}

export default HasNoStory;