import React from 'react';
import { connect } from 'react-redux';
import history from '../../history';
class Error404 extends React.Component {
  handleOkButton = (e) => {
    e.preventDefault();
    history.push('/');
  }
  componentDidMount() {
    // document.getElementById('redirect').click();
    //window.open('https://tensorsocial.com')

    document.getElementsByClassName('app')[0].classList.add('auth-wrap');
  }
  componentWillUnmount() {
    document.getElementsByClassName('app')[0].classList.remove('auth-wrap');
  }
  // handleRedirect = () => {
  //   let a = document.createElement('a');
  //   a.href = "https://tensorsocial.com";
  //   a.target = '_blank';
  //   document.body.appendChild(a);
  //   a.click();
  //   a.parentNode.removeChild(a);
  // }
  handleLink = (e) => {
    e.preventDefault();
    window.open("https://btb-mgmt.com")
  }

  render() {
    return (
      <div className="pt-40">
        {/* <button type="button" id="redirect" onClick={(e) => this.handleLink(e)} style={{ display: 'none' }}>Redirect</button> */}
        <div className="main-site-content container">
          <div className="user-form-wrap">
            <div className="page-head">
              <img alt="" src="https://image.flaticon.com/icons/svg/148/148766.svg" className="response-svg" />
              <h1 className="error-text">Error</h1>
              <p className="sub-text">
                {"Invalid report"}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect(null, null)(Error404);