import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Badge, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTooltip } from '../../../../actions';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import highchartsMore from "highcharts/highcharts-more.js"
import solidGauge from "highcharts/modules/solid-gauge.js";
import { RenderButton } from '../../../../components';
highchartsMore(Highcharts);
solidGauge(Highcharts);
const gaugeOptions = (props) => {
  let data = props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_genders ? props.postAnalysisDetails.audience_likers.data.audience_genders.sort((a, b) => b.weight - a.weight) : [];
  let seriesData = data.map((obj, index) => ({
    name: capitalize(obj.code),
    radius: "100%",
    color: obj.code.toLowerCase() === 'female' ? "#F3211D" : "#00C2E6",
    innerRadius: "70%",
    y: index === 0 ? Math.round((obj.weight + data[index + 1].weight) * 100) : Math.round(obj.weight * 100)

  }))
  return {

    chart: {
      type: 'solidgauge',
      height: 150,
    },

    title: null,

    pane: {
      // center: ['50%', '50%'],
      size: '100%',
      // startAngle: 0,
      // endAngle: 360,
      background: [
        {
          outerRadius: "100%",
          innerRadius: "70%",
          backgroundColor: "#e8e8e8",
          borderWidth: 0
        }
      ]
    },

    tooltip: {
      enabled: false
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      tickPositions: []
    },

    plotOptions: {
      solidgauge: {
        dataLabels: {
          enabled: false
        },
        // linecap: "round",
        stickyTracking: true,
        rounded: false
      }
    },
    credits: {
      enabled: false
    },
    series: [
      {
        data: seriesData
      }
    ]
  }
}
const LikesGenderSplit = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Gender Split</span>  <span href="#" id="likesGenderSplit" className="tooltip-icon"></span></span>
          <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesGenderSplit" >
            {showTooltip('likesGenderSplit')}
        </UncontrolledTooltip>
        </CardTitle>
        <div className="card-content ch-300">
          {!props.postAnalysisDetails || !props.postAnalysisDetails.audience_likers || !props.postAnalysisDetails.audience_likers.data || !props.postAnalysisDetails.audience_likers.data.audience_genders ||
            !props.postAnalysisDetails.audience_likers.data.audience_genders.length ?  <span className="text-center text-grey "><p className="pt-100">No Record Found</p></span> :
            <>
              <div className="graph-wrap">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={gaugeOptions(props)}
                />
              </div>
              <ListGroup flush className="custom-bedge-list">
                {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_genders ? props.postAnalysisDetails.audience_likers.data.audience_genders.map((gender, index) => <ListGroupItem key={index} className={gender.code.toLowerCase() === 'female' ? 'female d-flex justify-content-between align-items-center' : "d-flex justify-content-between align-items-center"}>{capitalize(gender.code)}<Badge className="text-grey" color="">{(gender.weight * 100).toFixed(2) + "%"}</Badge></ListGroupItem>)
                  : null}
              </ListGroup>
            </>
          }
        </div>
        {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_genders && props.postAnalysisDetails.audience_likers.data.audience_genders.length ?
          <div className="bottom">
            <Button onClick={() => props.toggleModal("likesGenderSplitModal")} color="link">View More</Button>
            <Modal id="likesGenderSplitModalDownload" isOpen={props.state.modals.likesGenderSplitModal} toggle={() => props.toggleModal("likesGenderSplitModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesGenderSplitModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Gender Split</span><span href="#" id="likesGenderSplitModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesGenderSplitModal" >
                  {showTooltip('likesGenderSplitModal')}
            </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("likesGenderSplitModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Gender</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_genders ? props.postAnalysisDetails.audience_likers.data.audience_genders.map((gender, index) => <tr key={index}>
                      <th scope="row">{capitalize(gender.code)}</th>
                      <td className="text-right">{formatNumbersToCommaSeperated(gender.weight * props.postAnalysisDetails.posts_profile.likes)}</td>
                      <td className="text-right">{(gender.weight * 100).toFixed(2) + '%'}</td>
                    </tr>) :
                      <tr>
                        <td colSpan="3" align="center">No Record Found</td>
                      </tr>}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesGenderSplit
