import React from 'react'
// import { Row, Col, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { Row, Col } from 'reactstrap';
import { } from 'reactstrap';
const ProfileSection = (props) => {
  return (
    <Row>
      <Col md="8">
        <div className="heading-w-img">
          <div className="image-col"><img src={props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.picture} alt="img" /></div>
          <div className="heading">
            <h1>{props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.is_verified ? <span>{props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.fullname}</span> : props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.fullname}</h1>
            <div className="sub-head"><a rel="noopener noreferrer" target="_blank" href={props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.url}>{props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.username}</a>{props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.is_business ? ', business account' : ''} </div>
          </div>
        </div>

      </Col>
      {/* <Col md="4">
        <UncontrolledButtonDropdown className="float-md-right csv-dropdown download-button-top">
          <DropdownToggle caret color="white" className="btn btn-outline-primary btn-export-data btn-with-caret">
            Download
         </DropdownToggle>
          <DropdownMenu className="ddmenu-col width-md">
            <DropdownItem onClick={() => props.downloadPdf('app')}>Download Pdf</DropdownItem>
          </DropdownMenu>
        </UncontrolledButtonDropdown>
      </Col> */}
    </Row>
  )
}

export default ProfileSection;
