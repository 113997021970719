import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Badge, Progress, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle, showTooltip } from '../../../../actions';
import { RenderButton } from '../../../../components';

const LikesLocationByCountry = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Location by Country</CardTitle>
        <div className="card-content ch-10 ">
          <ListGroup flush className="country-list with-progress">
            {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_geo && props.accountOverviewDetails.audience_likers.data.audience_geo.countries ?
              props.accountOverviewDetails.audience_likers.data.audience_geo.countries.map((country, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="country-text-wrap">
                        <div className="flag-img">{<img src={props.generateImgUrl('country', country.code)} alt="img" />}</div>
                        <div id={`likes_${country.name}`} title={showTitle(`likes_${country.name}`, country.name)} className="country-name">{capitalize(country.name)}</div>
                      </div>
                      <Badge className="text-grey" color="">{(country.weight * 100).toFixed(2) + '%'}</Badge>
                    </div>

                    <Progress value={(country.weight * 100).toFixed(2)} className="progress-bar-custom" />

                  </ListGroupItem>


                }
                return null;
              }
              ) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}

          </ListGroup>
        </div>
        {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_geo && props.accountOverviewDetails.audience_likers.data.audience_geo.countries ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesLocationByCountryModal")}>View More</Button>
            <Modal id="likesLocationByCountryModalDownload" isOpen={props.state.modals.likesLocationByCountryModal} toggle={() => props.toggleModal("likesLocationByCountryModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesLocationByCountryModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Location by Country</span>  <span href="#" id="likesLocationByCountryModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesLocationByCountryModal" >
                 {showTooltip('likesLocationByCountryModal')}
        </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("likesLocationByCountryModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <div className="multiple-tbl">
                  <h4>Location by Country</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>Country</th>
                        <th className="text-right">Followers</th>
                        <th className="text-right">Likes</th>
                        <th className="text-right">Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_geo && props.accountOverviewDetails.audience_likers.data.audience_geo.countries ?
                        props.accountOverviewDetails.audience_likers.data.audience_geo.countries.map((country, index) =>
                          <tr key={index}>
                            <th scope="row"><div className="country-text-wrap">
                              <div className="flag-img">{<img src={props.generateImgUrl('country', country.code)} alt="img" />}</div>
                              <div className="country-name">{capitalize(country.name)}</div>
                            </div></th>
                            <td className="text-right">{formatNumbersToCommaSeperated((country.weight) * props.accountOverviewDetails.user_profile.followers)}</td>
                            <td className="text-right">{formatNumbersToCommaSeperated((country.weight) * props.accountOverviewDetails.user_profile.avg_likes)}</td>
                            <td className="text-right">{(country.weight * 100).toFixed(2) + '%'}</td>
                          </tr>) :
                        <tr>
                          <td align="center" colSpan="4">No Record Found</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </div>
                <div className="multiple-tbl">
                  <h4>Location by State</h4>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th>State</th>
                        <th className="text-right">Followers</th>
                        <th className="text-right">Likes</th>
                        <th className="text-right">Percentage</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_geo && props.accountOverviewDetails.audience_likers.data.audience_geo.states ?
                        props.accountOverviewDetails.audience_likers.data.audience_geo.states.map((state, index) =>
                          <tr key={index}>
                            <th scope="row"><div className="country-text-wrap">
                              <div className="flag-img">{<img src={props.generateImgUrl('country', state.country && state.country.code)} alt="img" />}</div>
                              <div className="country-name">{capitalize(state.name)}</div>
                            </div></th>
                            <td className="text-right">{formatNumbersToCommaSeperated((state.weight) * props.accountOverviewDetails.user_profile.followers)}</td>
                            <td className="text-right">{formatNumbersToCommaSeperated((state.weight) * props.accountOverviewDetails.user_profile.avg_likes)}</td>
                            <td className="text-right">{(state.weight * 100).toFixed(2) + '%'}</td>
                          </tr>) :
                        <tr>
                          <td align="center" colSpan="4">No Record Found</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </div>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesLocationByCountry
