import React from 'react'
import { Row, Col, TabContent, Nav, NavLink as ReactStrapNavLink, NavItem, TabPane } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PostCard from './postCard';
import { CustomRightArrow, CustomLeftArrow } from './arrows';

const PostsSection = (props) => {
  const tabKey =
    props.state.activePostTab === '0' ? 'top_posts' : 'commercial_posts';
  let postsLength =
    props.accountOverviewDetails &&
    props.accountOverviewDetails.user_profile &&
    props.accountOverviewDetails.user_profile[tabKey] &&
    props.accountOverviewDetails.user_profile[tabKey].length;
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: postsLength ? (postsLength >= 3 ? 3 : postsLength) : 0
    },
    tablet: {
      breakpoint: { max: 992, min: 479 },
      items: postsLength ? (postsLength >= 2 ? 2 : postsLength) : 0
    },
    mobile: {
      breakpoint: { max: 479, min: 0 },
      items: postsLength ? (postsLength >= 1 ? 1 : postsLength) : 0
    }
  };
  let posts = props.accountOverviewDetails.user_profile[tabKey] || [];

  return (
    <div className="section section-po" id="Posts">
      <div className="section-head text-center">
        {/* <h2>Posts</h2> */}
        
      </div>
      <div className="follower-like-tabs">
      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.commercial_posts && props.accountOverviewDetails.user_profile.commercial_posts.length && props.accountOverviewDetails.user_profile.top_posts && props.accountOverviewDetails.user_profile.top_posts.length?
        <Nav pills className="d-flex justify-content-center">
          <NavItem>
            <ReactStrapNavLink
              className={
                props.state.activePostTab === '0' ? 'active first' : 'first'
              }
              onClick={() => props.togglePostTab('0')}
            >
              Popular
            </ReactStrapNavLink>
          </NavItem>
          <NavItem>
            <ReactStrapNavLink
              className={props.state.activePostTab === '1' ? 'active' : ''}
              onClick={() => props.togglePostTab('1')}
            >
              Sponsored
            </ReactStrapNavLink>
          </NavItem>
        </Nav>
        : null}
        <TabContent activeTab={props.state.activePostTab}>
          <TabPane tabId="0">
            <div className="section-head text-center">
              <h2>Popular Posts</h2>
              {posts.length === 1 && <span className="totalPosts">{posts.length} posts</span>}
            </div>
            {posts.length ?
              <Carousel
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                ssr={false} // means to render carousel on server-side.
                slidesToSlide={1}
                infinite={props.accountOverviewDetails.user_profile.top_posts.length > 3 ? true : false}
                autoPlay={false}
                autoPlaySpeed={1000}
                keyBoardControl={true}
                transitionDuration={500}
                itemClass={props.accountOverviewDetails.user_profile.top_posts.length > 3 ? "carouselitem" : "carouselitem ml-380"}
                deviceType={props.deviceType}
                customLeftArrow={<CustomRightArrow {...props} />}
                customRightArrow={<CustomLeftArrow  {...props} />}
              >
                {posts.map(
                  (post, index) => {
                    return <PostCard key={index} post={post} />;
                  }
                )}

              </Carousel> : null}
          </TabPane>
          <TabPane tabId="1">
            <div className="section-head text-center">
              <h2>Sponsored Posts</h2>
              {posts.length === 1 && <span className="total-posts">{posts.length} posts</span>}
            </div>
            {postsLength ?
              posts.length > 1 ? (
                <Carousel
                  swipeable={true}
                  // activeIndex={-1}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  slidesToSlide={1}
                  infinite={true}
                  autoPlay={false}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  transitionDuration={500}
                  itemClass="carouselitem"
                  deviceType={props.deviceType}
                  customLeftArrow={<CustomRightArrow {...props} />}
                  customRightArrow={<CustomLeftArrow {...props} totalPosts={posts.length} />}
                >
                  {posts.map(
                    (post, index) => {
                      return <PostCard key={index} post={post} id={`post_sponsered_${index}`} />;
                    }
                  )}
                </Carousel>) :
                <Row className="row-xs">
                  <Col sm="12" md={{ size: 4, offset: 4 }} className="carouselitem">
                    <PostCard post={posts[0]} />
                  </Col>
                </Row>
              : null}
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}
export default PostsSection;