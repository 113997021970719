import React from 'react'
import { Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { RenderButton } from '../../../../components';
const LikesPopularMentions = (props) => {
  return (

    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Popular @</CardTitle>
        <div className="popular-tags ch-5">
          <ul>
            {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.top_mentions && props.postAnalysisDetails.posts_profile.top_mentions.length ? props.postAnalysisDetails.posts_profile.top_mentions.map((tag, index) => {
              if (index < 5) {
                return <li key={index}>@{tag.tag}</li>
              }
              return null
            }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
          </ul>
        </div>
        {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.top_mentions && props.postAnalysisDetails.posts_profile.top_mentions.length ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesPopularMentionsModal")}>View More</Button>
            <Modal id="likesPopularMentionsModalDownload"
              isOpen={props.state.modals.likesPopularMentionsModal}
              toggle={() => props.toggleModal("likesPopularMentionsModal")}
              >
              <ModalHeader toggle={() => props.toggleModal("likesPopularMentionsModal")}>
                Popular @
                <RenderButton onClick={() => props.downloadPdf("likesPopularMentionsModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th colSpan="2">Mention</th>
                      <th className="text-right">Percent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.top_mentions && props.postAnalysisDetails.posts_profile.top_mentions.length ? props.postAnalysisDetails.posts_profile.top_mentions.map((tag, index) => <tr key={index}>
                      <th scope="row">{`@${tag.tag}`}</th>
                      <td className="text-left">&nbsp;</td>
                      <td className="text-right">{tag.weight.toFixed(2) + '%'}</td>
                    </tr>) :
                      <tr>
                        <td colSpan="3" align="center">No Record Found</td>
                      </tr>}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>

  )
}

export default LikesPopularMentions
