import React, { Component } from 'react';
import Routers from './routers/index'



class App extends Component {
  render() {
    return (

      <div className="app">

        <Routers />

      </div>

    );
  }
}

export default App;
