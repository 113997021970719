import React from 'react';
import {
  Navbar,
} from 'reactstrap';
import mainbrand from '../../img/Logo-BTB.jpg' //'../../img/LOGO_TS-1.svg';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
//import { RenderButton } from '../../components';
//import history from '../../history';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeTab: 0,
    };
  }

  handleReturn = (e) => {
    e.preventDefault();
    window.open("https://btb-mgmt.com")
  }

  render() {
    // console.log(this.props);
    return (
      <header id="header" className="d-flex w-100 navbar-wrap background-white">
        <Navbar className="grey d-flex w-100" light expand="md">
          <NavLink to='/' exact className="navbar-brand" ><img className="logo_pic" src={mainbrand} alt="homepage" /></NavLink>
        </Navbar>
        {/*<RenderButton  className="btn btn-primary return-home-btn" onClick={(e) => this.handleReturn(e)}>Return Home</RenderButton>*/}      </header>
    )
  }
}


export default connect(null, null)(Header);
