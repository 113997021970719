import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { RenderButton } from '../../../../components';
import { capitalize, formatNumbersToCommaSeperated, showTitle, showTooltip } from '../../../../actions';
const FollowersAudienceInterests = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Audience Interests</CardTitle>
        <div className="card-content ch-8">
          <ListGroup className="icon-list">
            {props.accountOverviewDetails &&
              props.accountOverviewDetails.audience_followers &&
              props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_interests ? props.accountOverviewDetails.audience_followers.data.audience_interests.map((interest, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index} className="d-flex align-items-center pl-0">
                    <div className="mr-3">
                      <img src={props.generateImgUrl('interest', interest.id)} alt="img" /></div>
                    <div id={`followers_${interest.name}`} title={showTitle(`followers_${interest.name}`, interest.name)} className="text-wrap">
                      {capitalize(interest.name)}
                    </div>
                  </ListGroupItem>
                }
                return ""
              }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
          </ListGroup>
        </div>
        {props.accountOverviewDetails &&
          props.accountOverviewDetails.audience_followers &&
          props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_interests ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("followersAudienceInterestsModal")}>View More</Button>
            <Modal id="followersAudienceInterestsModalDownload" isOpen={props.state.modals.followersAudienceInterestsModal} toggle={() => props.toggleModal("followersAudienceInterestsModal")} >
              <ModalHeader toggle={() => props.toggleModal("followersAudienceInterestsModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Audience Interests</span>  <span href="#" id="followersAudienceInterestsModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="followersAudienceInterestsModal" >
                  {showTooltip('followersAudienceInterestsModal')}
              </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("followersAudienceInterestsModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>

                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Interests</th>
                      <th className="text-right">Followers</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>

                    {props.accountOverviewDetails &&
                      props.accountOverviewDetails.audience_followers &&
                      props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_interests ? props.accountOverviewDetails.audience_followers.data.audience_interests.map((interest, index) =>
                        <tr key={index}>
                          <th scope="row">
                            <div className="country-text-wrap">
                              <div className="flag-img brand-img">
                                <img src={props.generateImgUrl('interest', interest.id)} alt="img" /></div>
                              <div className="country-name">{capitalize(interest.name)}</div>
                            </div>
                          </th>
                          <td className="text-right">{formatNumbersToCommaSeperated((interest.weight) * props.accountOverviewDetails.user_profile.followers)}</td>
                          <td className="text-right">{formatNumbersToCommaSeperated((interest.weight) * props.accountOverviewDetails.user_profile.avg_likes)}</td>
                          <td className="text-right">{(interest.weight * 100).toFixed(2) + '%'}</td>
                        </tr>) :
                      <tr>
                        <td align="center" colSpan="4">No Record Found</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>

    </Card>
  )
}

export default FollowersAudienceInterests
