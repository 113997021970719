const toolTipOptions = [
    {
        id: 'search', value: 'The most relevant hashtags are colored in dark blue, the least relevant in light blue, and those of average relevance are in intermediate colors. The size of each hashtag is proportional to its popularity.'
    },
    {
        id: 'languageAudience', value: 'Identify influencers by their audience location. We determine audience location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several audience locations and specify minimum percentage for each location, your search results will be refined with influencers that have audience in ANY of the specified locations.'
    },
    {
        id: 'languageInfluencer', value: 'Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations. '
    },
    {
        id: 'locationAudience', value: 'Identify influencers by their audience location. We determine audience location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several audience locations and specify minimum percentage for each location, your search results will be refined with influencers that have audience in ANY of the specified locations.'
    },
    {
        id: 'locationInfluencer', value: 'Identify influencers by their location. We determine influencer location by analyzing location tags, language and caption of recent posts and text in profile bio. You can add several influencer locations and your search results will be refined with influencers available in ANY of the specified locations. '
    },
    {
        id: 'interestsAudience', value: 'Identify influencers by their audience interests. We determine interest of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and specify minimum percentage for each interest, your search results will be refined with influencers that have audience mentioning ANY of the specified interests. '
    },
    {
        id: 'interestsInfluencer', value: 'Identify influencers by their interests. We determine interest of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several interests and your search results will be refined with influencers that have ANY of the specified interests.'
    },
    {
        id: 'brandsAudience', value: 'Identify influencers by their audience brand affinities. We determine brand affinities of audience by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and specify minimum percentage for each brand, your search results will be refined with influencers that have audience mentioning ANY of the specified brands.'
    },
    {
        id: 'brandsInfluencer', value: 'Identify influencers by their brand affinities. We determine brand affinities of influencers by analyzing posts for caption text, mentions, hashtags and location tags. You can add several brands and your search results will be refined with influencers that have ANY of the specified brands.'
    },
    {
        id: 'genderAudience', value: 'Identify influencers by their audience gender. We determine gender of audience by analyzing profile photos, name and text in profile bio and selfies in recent posts. '
    },
    {
        id: 'genderInfluencer', value: 'Identify influencers by gender. We determine gender of influencers by analyzing profile photos, name and text in profile bio and selfies in recent posts. '
    },
    {
        id: 'ethnicityAudience', value: 'Identify influencers by their audience ethnicity. We determine ethnicity of audience by analyzing profile photos and selfies in recent posts.'
    },
    {
        id: 'ageAudience', value: 'Identify influencers by their audience age. We determine age of audience by analyzing profile photos and selfies in recent posts'
    },
    {
        id: 'ageInfluencer', value: 'Identify influencers by their audience age. We determine age of audience by analyzing profile photos and selfies in recent posts'
    },
    {
        id: 'lookAudience', value: 'Audience lookalikes help you find influencers that have a similar audience.'
    },
    {
        id: 'audienceTypeAudience', value: 'Audience lookalikes help you find influencers that have a similar audience.'
    },
    {
        id:'bio',value:'Identify influencers by keywords within their bio description.'
    },
    {
        id: 'engagementInfluencer', value: 'Identify influencers by average number of likes and comments in their recent posts. '
    },
    {
        id:'followerRate',value:'Identify influencers by their number of followers. '
    },
    {
        id:'engagementRate',value:'Identify influencers by their engagement rate. ER is average likes of their recent posts divided by their followers.'
    },
    {
        id: 'contacts', value: 'Refine search results with only influencers that have email or other contact details. '
    },
    {
        id:'accountType',value:'Identify influencers by their Instagram account type - Business, Creator or Regular (non business) Instagram account. '
    },
    {
        id: 'is_verified', value: 'Refine search results with only influencers that have a verified account.'
    },
    {
        id:'onlyCredibleAccount',value:'Refine search results with only influencers that have a credibility greater than 90%.'
    },
    {
        id:'is_hidden',value:'Identify influencers by their Instagram account type - Business, Creator or Regular (non business) Instagram account. '
    },
    {
        id: 'has_audience_data', value: 'Refine search results with only influencers that have an Audience Data report. '
    },
    {
        id:'hasContactDetails',value:'Refine search results with only influencers that have email or other contact details'
    },
    {
        id:'hasSponseredPosts',value:'Refine search results with only influencers with sponsored posts'
    },
    {
        id: 'influencerLocation', value: 'Get report on influencers followers & active audience from specific locations only. You can add several audience locations.'
    },
    {
        id: 'influencerLanguage', value: 'Get report on influencers followers & active audience speaking specific language. You can add several audience languages.'
    },
    {
        id: 'influencerBrands', value: "Get report on influencer's followers & active audience interested in specific brand. You can add several audience brands."
    },
    {
        id: 'influencerInterests', value: "Get report on influencer's followers & active audience on specific interest. You can add several audience interests."
    },
    {
        id: 'influencerAge', value: "Get report on influencer's followers & active audience for specific age group."
    },
    {
        id: 'influencerReachability', value: "Get report on influencer's followers & active audience with specific number of their followings"
    },
    {
        id: 'influencerCredibility', value: "Get report on influencer's followers & active audience for specific credibility."
    },
    {
        id: 'influencerGender', value: "Get report on influencer's followers & active audience on specific gender."
    }, 
    {
        id: 'reportAvgLikes', value: "The average sum of likes on the last 30 posts."
    },
    {
        id:'avgLikes',value:'The average sum of likes on the last 30 posts.'
    },
    {
        id:'paidPostTooltip',value:'Most frequently used hashtags on the last 30 posts.'
    },
    {
        id:'engagementTooltip',value:'Average likes divided by followers'
    },
    {
        id:'engagementRateModal',value:'Average likes divided by followers'
    },
    {
        id:'followersAudienceBrandAffinityModal',value:'We determine brand affinities by analyzing posts for caption texts, mentions, hashtags and location tags.'
    },
    {
        id:'likesAudienceInterestsModal',value:'We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.'
    },
    {
        id:'followersAudienceInterestsModal',value:'We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.'
    },
    {
        id:'followersFollowersCredibility',value:'To establish the followers credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above.'
    },
    {
        id:'likessFollowersCredibility',value:'To establish the liker credibility score for historical followers we take into account factors such as an account’s avatar and bio description, number of posts, number of accounts followed vs following ratio. Influencers with a genuine audience will attain scores of 80 or above.'
    },
    {
        id:'followersNotableFiltersModal',value:'Shows what percentage of followers are influencers. Notable Followers for a 0.99 quantile can largely differ from 1% to 20%.'
    },
    {
        id:'notableUsers',value:'Shows what percentage of followers are influencers. Notable Followers for a 0.99 quantile can largely differ from 1% to 20%.'
    },
    {
        id:'likesNotableFiltersModal',value:'Top followers of this user with more than 1,000 followers.'
    },
    {
        id:'likesAudienceReachability',value:'Shows percentage of followers that follow more than 1500 accounts, between 1000-1500, 500-1000 and below 500. Accounts following more than 1.5k accounts will most likely not see the sponsored posts.'
    },
    {
        id:'followersAudienceReachability',value:'Shows percentage of followers that follow more than 1500 accounts, between 1000-1500, 500-1000 and below 500. Accounts following more than 1.5k accounts will most likely not see the sponsored posts.'
    },
    {
        id:'followersAgeAndGenderSplitModal',value:'We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.'
    },
    {
        id:'followersAgeAndGenderSplit',value:'We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.'
    },
    {
        id:'likesAgeAndGenderSplitModal',value:'We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.'
    },
    {
        id:'likesAgeAndGenderSplit',value:'We determine historical followers age by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.'
    },
    {
        id:'likesGenderSplitModal',value:'We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.'
    },
    {
        id:'likesGenderSplit',value:'We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.'
    },
    {
        id:'followersGenderSplit',value:'We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.'
    },
    {
        id:'followersGenderSplitModal',value:'We determine historical followers gender by analyzing the profile picture, name, text in the profile description (bio) and selfies in recent posts.'
    },
    {
        id:'likesLocationByCityModal',value:'We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).'
    },
    {
        id:'likesLocationByCity',value:'We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).'
    },
    {
        id:'followersLocationByCity',value:'We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).'
    },
    {
        id:'followersLocationByCityModal',value:'We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).'
    },
    {
        id:'likesLocationByCountryModal',value:'We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).'
    },
    {
        id:'followersLocationByCountryModal',value:'We determine historical followers location (country, state, city) by analyzing location tags, language and caption of the recent posts and text in profile description (bio).'
    },
    {
        id:'followersEthnicityModal',value:'We determine historical followers ethnicity by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.'
    },
    {
        id:'likesEthnicityModal',value:'We determine historical followers ethnicity by analyzing the profile picture, text in the profile description (bio) and selfies in recent posts. Only available for the audience as an aggregated percentage.'
    },
    {
        id:'likesLanguageModal',value:'We determine historical followers language by analyzing caption of the recent posts.'
    },
    {
        id:'followersLanguageModal',value:'We determine historical followers language by analyzing caption of the recent posts.'
    },
    {
        id:'popular@',value:'Most frequently used mentions on the last 30 posts.'
    },
    {
        id:' Popular #',value:'Most frequently used hashtags on the last 30 posts.'
    },
    {
        id:'influencersInterestsModal',value:'We determine interests by analyzing posts for caption texts, @mentions, #hashtags and location tags.'
    },
    {
        id:'influencersBrandAffinityModal',value:'We determine brand affinities by analyzing posts for caption texts, mentions, hashtags and location tags.'
    }





];
export default toolTipOptions;