import React from 'react'
import { Row, Col, TabContent, Nav, NavItem, NavLink as ReactStrapNavLink, Card, CardBody, CardTitle, TabPane, UncontrolledTooltip } from 'reactstrap';
import FollowersGenderSplit from './byFollowers/followersGenderSplit';
import FollowersAgeAndGenderSplit from './byFollowers/followersAgeAndGenderSplit';
import FollowersLocationByCity from './byFollowers/followersLocationByCity';
import FollowersLocationByCountry from './byFollowers/followersLocationByCountry';
import FollowersEthnicity from './byFollowers/followersEthnicity';
import FollowersLanguage from './byFollowers/followersLanguage';
import FollowersAudienceBrandAffinity from './byFollowers/followersAudienceBrandAffinity';
import FollowersAudienceInterests from './byFollowers/followersAudienceInterests';
import FollowersAudienceLookAlikes from './byFollowers/followersAudienceLookAlikes';
import FollowersNotableFollowers from './byFollowers/followersNotableFollowers';
import LikesGenderSplit from './byLikes/likesGenderSplit';
import LikesAgeAndGenderSplit from './byLikes/likesAgeAndGenderSplit';
import LikesLocationByCity from './byLikes/likesLocationByCity';
import LikesLocationByCountry from './byLikes/likesLocationByCountry';
import LikesEthnicity from './byLikes/likesEthnicity';
import LikesLanguage from './byLikes/likesLanguage';
import LikesAudienceBrandAffinity from './byLikes/likesAudienceBrandAffinity';
import LikesAudienceInterests from './byLikes/likesAudienceInterests';
// import LikesAudienceLookAlikes from './byLikes/likesAudienceLookAlikes';
import LikesNotableUsers from './byLikes/likesNotableUsers';
import FollowersAudienceReachability from './byFollowers/followersAudienceReachability';
import LikesAudienceReachability from './byLikes/likesAudienceReachability';
import { showTooltip } from '../../../actions';
const AudiencesSection = (props) => {
  const followersLikeIconClass = props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.credibility_class ? ((props.accountOverviewDetails.audience_followers.data.credibility_class.toLowerCase() === 'high' || props.accountOverviewDetails.audience_followers.data.credibility_class.toLowerCase() === 'best') ? 'icon-liked' : (props.accountOverviewDetails.audience_followers.data.credibility_class.toLowerCase() === 'bad' ? 'icon-unlike' : 'icon-neutral')) : "";
  const likesLikeIconClass = props.accountOverviewDetails && props.accountOverviewDetails.audience_likers &&
    props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.credibility_class ? ((props.accountOverviewDetails.audience_likers.data.credibility_class.toLowerCase() === 'high' || props.accountOverviewDetails.audience_likers.data.credibility_class.toLowerCase() === 'best') ? 'icon-liked' : (props.accountOverviewDetails.audience_likers.data.credibility_class.toLowerCase() === 'bad' ? 'icon-unlike' : 'icon-neutral')) : "";
  return (
    <div className="section section-au" id="Audience">
      <div className="section-head text-center">
        <h2>Audience Details</h2>
      </div>

      <div className="follower-like-tabs">
        <Nav pills className="d-flex justify-content-center">
          <NavItem>
            <ReactStrapNavLink
              className={props.state.activeTab === '0' ? 'active first' : 'first'}
              onClick={() => props.toggle('0')}
            >
              Followers
            </ReactStrapNavLink>
          </NavItem>
          <NavItem>
            <ReactStrapNavLink
              className={props.state.activeTab === '1' ? 'active' : ''}
              onClick={() => props.toggle('1')}
            >
              Likes
            </ReactStrapNavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={props.state.activeTab}>
          <TabPane tabId="0">
            <Row className="row-xs">
              <Col sm="6">
                <Card className="card-content-custom ch-179">
                  <CardBody>
                    <h2 className={followersLikeIconClass}>{props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_credibility && (props.accountOverviewDetails.audience_followers.data.audience_credibility * 100).toFixed(2) + '%'}</h2>
                    <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Followers Credibility</span>  <span href="#" id="followersFollowersCredibility" className="tooltip-icon"></span></span>
                      <UncontrolledTooltip placement="bottom" className="tooltip-content" target="followersFollowersCredibility">
                        {showTooltip('followersFollowersCredibility')}
                      </UncontrolledTooltip>
                    </CardTitle>
                  </CardBody>
                </Card>

                <Card className="card-content-custom ch-179">
                  <CardBody>
                    <h2>{props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.notable_users_ratio && (props.accountOverviewDetails.audience_followers.data.notable_users_ratio * 100).toFixed(2) + '%'}</h2>
                    <CardTitle>
                      Notable Users
                      </CardTitle>
                  </CardBody>
                </Card>
              </Col>

              <Col sm="6">
                <FollowersAudienceReachability {...props} />
              </Col>

            </Row>

            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_genders || !props.accountOverviewDetails.audience_followers.data.audience_genders.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_genders_per_age || !props.accountOverviewDetails.audience_followers.data.audience_genders_per_age.length)
              ? null :
              <Row className="row-xs">
                <Col sm="5" md="4">
                  <FollowersGenderSplit {...props} />
                </Col>
                <Col sm="7" md="8">
                  <FollowersAgeAndGenderSplit {...props} />
                </Col>
              </Row>
            }
            {!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_geo || !props.accountOverviewDetails.audience_followers.data.audience_geo.cities || !props.accountOverviewDetails.audience_followers.data.audience_geo.cities.length
              ? null :
              <Row>
                <Col>
                  <FollowersLocationByCity {...props} />
                </Col>
              </Row>
            }
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_geo || !props.accountOverviewDetails.audience_followers.data.audience_geo.countries || !props.accountOverviewDetails.audience_followers.data.audience_geo.countries.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_ethnicities || !props.accountOverviewDetails.audience_followers.data.audience_ethnicities.length)
              && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_languages || !props.accountOverviewDetails.audience_followers.data.audience_languages.length)
              ? null :
              <Row className="row-xs">
                <Col md="4">
                  <FollowersLocationByCountry {...props} />
                </Col>

                <Col md="4">
                  <FollowersEthnicity {...props} />
                </Col>

                <Col md="4">
                  <FollowersLanguage {...props} />
                </Col>
              </Row>
            }
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_brand_affinity || !props.accountOverviewDetails.audience_followers.data.audience_brand_affinity.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_interests || !props.accountOverviewDetails.audience_followers.data.audience_interests.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <FollowersAudienceBrandAffinity {...props} />
                </Col>

                <Col sm="6">
                  <FollowersAudienceInterests {...props} />
                </Col>

              </Row>
            }
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.audience_lookalikes || !props.accountOverviewDetails.audience_followers.data.audience_lookalikes.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_followers || !props.accountOverviewDetails.audience_followers.data || !props.accountOverviewDetails.audience_followers.data.notable_users || !props.accountOverviewDetails.audience_followers.data.notable_users.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <FollowersAudienceLookAlikes {...props} />
                </Col>

                <Col sm="6">
                  <FollowersNotableFollowers  {...props} />
                </Col>

              </Row>
            }
          </TabPane>
          <TabPane tabId="1">
            <Row className="row-xs">
              <Col sm="6">
                <Row>
                  <Col sm="12">
                    <Card className="card-content-custom ch-112">
                      <CardBody>
                        <h2 className={likesLikeIconClass}>{props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_credibility
                          && (props.accountOverviewDetails.audience_likers.data.audience_credibility * 100).toFixed(2) + '%'}</h2>
                        <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Likers Credibility</span>  <span href="#" id="likessFollowersCredibility" className="tooltip-icon"></span></span>
                          <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likessFollowersCredibility">
                           {showTooltip('likessFollowersCredibility')}
                      </UncontrolledTooltip>
                        </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="12">
                    <Card className="card-content-custom ch-112">
                      <CardBody>
                        <h2>{props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.notable_users_ratio && (props.accountOverviewDetails.audience_likers.data.notable_users_ratio * 100).toFixed(2) + '%'}</h2>
                        <CardTitle>
                          Notable Likes
                      </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col sm="12">
                    <Card className="card-content-custom ch-112">
                      <CardBody>
                        <h2>{props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.likes_not_from_followers && (props.accountOverviewDetails.audience_likers.data.likes_not_from_followers * 100).toFixed(2) + '%'}</h2>
                        <CardTitle>
                          Likes not from Followers
                      </CardTitle>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>

              </Col>


              <Col sm="6">
                <LikesAudienceReachability {...props} />
              </Col>

            </Row>
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_genders || !props.accountOverviewDetails.audience_likers.data.audience_genders.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_genders_per_age || !props.accountOverviewDetails.audience_likers.data.audience_genders_per_age.length)
              ? null :
              <Row className="row-xs">
                <Col sm="5" md="4">
                  <LikesGenderSplit {...props} />
                </Col>
                <Col sm="7" md="8">
                  <LikesAgeAndGenderSplit {...props} />
                </Col>
              </Row>
            }
            {!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_geo || !props.accountOverviewDetails.audience_likers.data.audience_geo.cities || !props.accountOverviewDetails.audience_likers.data.audience_geo.cities.length
              ? null :
              <Row>
                <Col>
                  <LikesLocationByCity {...props} />
                </Col>
              </Row>
            }
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_geo || !props.accountOverviewDetails.audience_likers.data.audience_geo.countries || !props.accountOverviewDetails.audience_likers.data.audience_geo.countries.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_ethnicities || !props.accountOverviewDetails.audience_likers.data.audience_ethnicities.length)
              && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_languages || !props.accountOverviewDetails.audience_likers.data.audience_languages.length)
              ? null :
              <Row className="row-xs">
                <Col md="4">
                  <LikesLocationByCountry {...props} />
                </Col>

                <Col md="4">
                  <LikesEthnicity {...props} />
                </Col>

                <Col md="4">
                  <LikesLanguage {...props} />
                </Col>
              </Row>
            }
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_brand_affinity || !props.accountOverviewDetails.audience_likers.data.audience_brand_affinity.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.audience_interests || !props.accountOverviewDetails.audience_likers.data.audience_interests.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <LikesAudienceBrandAffinity {...props} />
                </Col>

                <Col sm="6">
                  <LikesAudienceInterests {...props} />
                </Col>
              </Row>
            }
            {(!props.accountOverviewDetails || !props.accountOverviewDetails.audience_likers || !props.accountOverviewDetails.audience_likers.data || !props.accountOverviewDetails.audience_likers.data.notable_users || !props.accountOverviewDetails.audience_likers.data.notable_users.length)
              ? null :
              <Row className="row-xs">
                <Col sm="6">
                  <LikesNotableUsers  {...props} />
                </Col>
              </Row>
            }
          </TabPane>
        </TabContent>
      </div>
    </div>
  )
}
export default AudiencesSection;