import React from 'react';
import { Button, Input, Label } from 'reactstrap';
// import Slider from 'rc-slider';
// import Tooltip from 'rc-tooltip';
// import 'rc-tooltip/assets/bootstrap.css';
import ReactSelect, { components } from 'react-select';
import { titlelize } from '../actions';

// const Handle = Slider.Handle;

// const handle = (props) => {
//   const { value, dragging, index, ...restProps } = props;
//   return (
//     <Tooltip
//       prefixCls="rc-slider-tooltip"
//       overlay={value}
//       visible={dragging}
//       placement="top"
//       key={index}
//     >
//       <Handle value={value} {...restProps} />
//     </Tooltip>
//   );
// };
export const RenderButton = (props) => {
  return <Button
    {...props}
    type="button"

  >{props.children}</Button>
}
// export const RenderSlider = (props) => (<Slider {...props} className='slider' handle={handle} />)
export const RenderTextField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input
    {...field}
    {...customProps}
    type="text"
    autoComplete="new-email"
  />
}
export const RenderNumericField = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  delete customProps.form;
  return <Input
    {...field}
    {...customProps}
    type="number"
    autoComplete="new-email-0"
  />
}
export const RenderEmailField = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {
  delete props.form;
  return <Input
    // {...field}
    {...props}
    // placeholder={props.placeholder}
    type="email"
    autoComplete="new-email"
  />
}

export const RenderSingleSelect = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#00c2e6' : '#fff',
    }),
  }
  return (
    <ReactSelect

    {...field}
      {...customProps}
      isMulti={false}
      placeholder={customProps.placeholder && titlelize(customProps.placeholder)}
      isClearable={false}
      isSearchable={false}
      styles={customStyles}
      backspaceRemovesValue={true}
      deleteRemoves={true}
      // components={{ClearIndicator}}
    />

  )
}

export const RenderSelect = ({
  field, // { name, value, onChange, onBlur }
  // form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...customProps
}) => {
  let appendValue = customProps.appendValue;
  delete customProps.appendValue;
  return (
    <Input
      {...field}
      {...customProps}
      // value={props.value}
      // onChange={props.onChange}
      type="select" >
      <option value={""}>Select {appendValue || ''}</option>
      {customProps.options && customProps.options.map((option, index) => (<option disabled={option.disabled} key={index} value={option.value}>{option.text}</option>))}
    </Input>
  )
}

export const RenderSearchableSingleSelect = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#00c2e6' : '#fff',
    }),
  }
  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" />
        </components.DropdownIndicator>
      )
    );
  };
  const ClearIndicator = props => {
    return null;
  }

  return (
    <ReactSelect
      {...field}
      {...customProps}
      isMulti={false}
      placeholder={customProps.placeholder && titlelize(customProps.placeholder)}
      isClearable={true}
      isSearchable={true}
      styles={customStyles}
      backspaceRemovesValue={true}
      deleteRemoves={true}
      components={{ ClearIndicator, DropdownIndicator }}
    />

  )
}
export const RenderMutipleDropdown = ({ field,
  ...customProps }) => {

  const MultiValue = ({ children, ...props }) => {


    return (
      <components.MultiValue {...props} >
        <components.MultiValueContainer {...props} >
          <components.MultiValueLabel>{!props.selectProps.menuIsOpen && ''}</components.MultiValueLabel>
        </components.MultiValueContainer>
      </components.MultiValue >
    );
  };

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <i className="fa fa-search" />
        </components.DropdownIndicator>
      )
    );
  };
  const Comps = { MultiValue, DropdownIndicator }
  return (
    <ReactSelect

      {...field}
      {...customProps}
      isMulti={true}
      autosize={false}
      isClearable={false}
      styles={{
        multiValueRemove: base => ({
          ...base,
          display: 'none',
        }),
      }}
      isSearchable={true}
      components={Comps}
    />
  )
}

export const SubmitButton = ({
  ...props
}) => {
  return <Button
    {...props}
    type='submit'
    color={props.color || 'primary'}
  >{props.children}</Button>
}
export const RenderPasswordField = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  delete customProps.form;
  return <Input
    {...field}
    {...customProps}
    autoComplete="username password"
    type="password"
  />
}

export const RenderTextArea = ({
  field, // { name, value, onChange, onBlur }
  ...customProps
}) => {
  return <Input
    {...field}
    {...customProps}
    type="textarea" />
}
export const RenderRadioButton = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {

  return <Input
    {...field}
    {...props}
    type="radio" />
}
export const RenderLabel = ({
  ...props
}) => {
  return <Label
    {...props}>{props.children}</Label>
}
export const RenderCheckBox = ({
  field, // { name, value, onChange, onBlur }
  ...props
}) => {

  return <Input
    {...field}
    {...props}

    type="checkbox" />
}


