import React from 'react'
import { Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import { RenderButton } from '../../../components';
const PopularMentions = (props) => {
  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Popular @</CardTitle>
          <div className="popular-tags ch-5">
            <ul>
              {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.top_mentions ? props.accountOverviewDetails.user_profile.top_mentions.map((tag, index) => {
                if (index < 5) {
                  return <li key={index}>@{tag.tag}</li>
                }
                return null
              }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
            </ul>
          </div>
          {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.top_mentions ?
            <div className="bottom">
              <Button color="link" onClick={() => props.toggleModal("popularAtTheRateModal")}>View More</Button>
              <Modal id="popularAtTheRateModalDownload"
                isOpen={props.state.modals.popularAtTheRateModal}
                toggle={() => props.toggleModal("popularAtTheRateModal")}
                >
                <ModalHeader toggle={() => props.toggleModal("popularAtTheRateModal")}>
                  Popular @
                <RenderButton onClick={() => props.downloadPdf("popularAtTheRateModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
                </ModalHeader>
                <ModalBody>
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan="2">Mention</th>
                        <th className="text-right">Percent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.top_mentions && props.accountOverviewDetails.user_profile.top_mentions.length ? props.accountOverviewDetails.user_profile.top_mentions.map((tag, index) => <tr key={index}>
                        <th scope="row">{`@${tag.tag}`}</th>
                        <td className="text-left">&nbsp;</td>
                        <td className="text-right">{(tag.weight * 100).toFixed(2) + '%'}</td>
                      </tr>) :
                        <tr>
                          <td colSpan="3" align="center">No Record Found</td>
                        </tr>}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
            : null}
        </CardBody>
      </Card>
    </Col>
  )
}

export default PopularMentions
