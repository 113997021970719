import React from 'react'
import { Row, Col, Card, CardBody, CardImg, CardText, CardLink } from 'reactstrap';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import moment from 'moment';
import { formatNumbersToCommaSeperated } from '../../actions';
import { CustomRightArrow, CustomLeftArrow } from './arrows';

const PostsSection = (props) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.posts && props.postAnalysisDetails.posts_profile.posts.length ? (props.postAnalysisDetails.posts_profile.posts.length >= 3 ? 3 : props.postAnalysisDetails.posts_profile.posts.length) : 0,
    },
    tablet: {
      breakpoint: { max: 992, min: 479 },
      items: props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.posts && props.postAnalysisDetails.posts_profile.posts.length ? (props.postAnalysisDetails.posts_profile.posts.length >= 2 ? 2 : props.postAnalysisDetails.posts_profile.posts.length) : 0,
    },
    mobile: {
      breakpoint: { max: 479, min: 0 },
      items: props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.posts && props.postAnalysisDetails.posts_profile.posts.length ? (props.postAnalysisDetails.posts_profile.posts.length >= 1 ? 1 : props.postAnalysisDetails.posts_profile.posts.length) : 0,
    }
  };
  const textLength = window.innerWidth <= 3000 && window.innerWidth > 992 ? 118 : (window.innerWidth <= 992?( window.innerWidth >= 480 ? 80 : 126) : 126);
  return (
    <div className="section section-po" id="Posts">
      <div className="section-head text-center"><h2>Posts</h2></div>
      {props.postAnalysisDetails && props.postAnalysisDetails.posts_profile && props.postAnalysisDetails.posts_profile.posts && props.postAnalysisDetails.posts_profile.posts.length ?
        <Carousel
          swipeable={true}
          draggable={false}
          showDots={false}
          responsive={responsive}
          ssr={true} // means to render carousel on server-side.
          slidesToSlide={1}
          infinite={props.postAnalysisDetails.posts_profile.posts.length > 3 ? true : false}
          autoPlay={false}
          autoPlaySpeed={1000}
          keyBoardControl={true}
          transitionDuration={500}
          itemClass={props.postAnalysisDetails.posts_profile.posts.length > 3 ? "carouselitem" : "carouselitem ml-380"}
          deviceType={props.deviceType}
          customLeftArrow={<CustomRightArrow {...props} />}
          customRightArrow={<CustomLeftArrow  {...props} />}
        >
          {props.postAnalysisDetails.posts_profile.posts.map((post, index) => {

            return (<div key={index}>
              <Card className="">
                <CardLink href={post.link} target='_blank'><CardImg src={post.image} alt="Post Image" /></CardLink>
                <CardBody>
                  <div className="post-date">{moment(post.created).format('MMMM DD, YYYY, HH:mm:ss')}</div>
                  <CardText title={post.text && post.text.length > textLength ? post.text : ''}>{post.text && post.text.length > textLength ? (post.text.slice(0, textLength) + '..') : post.text}</CardText>
                  <Row className="row-xs">
                    <Col className="post-bottom col-6">
                      <span className="text-sm">Likes</span>
                      <h5 className="text-count">{formatNumbersToCommaSeperated(post.stat.likes)}</h5>
                    </Col>

                    <Col className="post-bottom col-6">
                      <span className="text-sm">Comments</span>
                      <h5 className="text-count">{formatNumbersToCommaSeperated(post.stat.comments)}</h5>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>)
          }
          )}

        </Carousel> : null}
    </div>
  )
}
export default PostsSection;