import React from 'react'
import { Container, Row, Col, TabContent, Nav, NavItem } from 'reactstrap';

import ProfileSection from './profileSection';
import InfulencersSection from './infulencersSection/infulencersSection';
import AudiencesSection from './audiencesSection/audiencesSection';
import PostsSection from './postSection';
import { Link } from "react-scroll";
import Stories from './stories';

import "./index.scss";
const ReportSection = (props) => {
  const calculateDuration = (scrollDistanceInPx) => {
    return (10 / 100) * scrollDistanceInPx;
  }

  return (
    <Container className="main-site-content">
      <div className="pl-2 pr-2">
        <ProfileSection {...props} downloadPdf={props.downloadPdf} />
        <Row>
          <Col md="12" className="iap-tabber">
            <Nav pills className="d-flex justify-content-center">
              <NavItem>
                <Link
                  onClick={() => props.handleActiveTab('0')}
                  className={props.state.activeMainTab === '0' ? 'active nav-link' : 'nav-link'} duration={(scrollDistanceInPx) => calculateDuration(scrollDistanceInPx)}
                  smooth={true}
                  to="Influencer">Influencer</Link>
              </NavItem>
              <NavItem>
                <Link
                  smooth={true}
                  to="Audience"
                  onClick={() => props.handleActiveTab('1')}
                  duration={(scrollDistanceInPx) => calculateDuration(scrollDistanceInPx)}
                  className={props.state.activeMainTab === '1' ? 'active nav-link' : 'nav-link'}  >Audience</Link>
              </NavItem>
              <NavItem>
                <Link
                  smooth={true}
                  onClick={() => props.handleActiveTab('2')}
                  duration={(scrollDistanceInPx) => calculateDuration(scrollDistanceInPx)} to="Posts"
                  className={props.state.activeMainTab === '2' ? 'active nav-link' : 'nav-link'}  >Posts</Link>
              </NavItem>
            </Nav>
            <TabContent>
              <InfulencersSection
                toggleModal={props.toggleModal}
                state={props.state}
                downloadPdf={props.downloadPdf}
                calculatePercentage={props.calculatePercentage}
                generateImgUrl={props.generateImgUrl}
                {...props}
              />
              <AudiencesSection
                {...props}
                toggleModal={props.toggleModal}
                state={props.state}
                downloadPdf={props.downloadPdf}
                downloadReportPdf={props.downloadReportPdf}
                generateImgUrl={props.generateImgUrl}
                toggle={props.toggle}
              />
              <PostsSection
                {...props}
                state={props.state}
         
  

              />
              <Stories {...props} />
            </TabContent>
          </Col>
        </Row>

      </div>
    </Container>
  )
}

export default ReportSection
