import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { formatName, capitalize, formatNumbersToCommaSeperated } from '../../../actions';
import AccountOverview from './accountOverview';
import PopularMentions from './popularMentions';
import PopularHashtags from './popularHashtags';
import EngagementsSpreadLastPosts from './engagementsSpreadLastPosts';
import LookAlikesSection from './lookAlikes';
import EngagementRateSection from './engagementRateSection';
import SocialCloud from './socialCloud';
import InfluencerBrandAffinity from './influencerBrandAffinity';
import InfluencerInterests from './influencerInterests';
const chartOptions = (props, type) => {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let data = props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.stat_history ? props.accountOverviewDetails.user_profile.stat_history.map((option) => {
    return option[type];
  }) : [];
  // data = data.reverse();

  let categories = props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.stat_history ? props.accountOverviewDetails.user_profile.stat_history.map((option) => {
    return `${months[parseInt(option.month.split("-").pop(), 10) - 1]}, ${option.month.split("-")[0]}`;
  }) : [];

  return {
    chart: {
      height: 200,
    },
    tooltip: {
      formatter: function () {
        return '<b>' + capitalize(this.series.name) + '</b><br/>' +
          this.x + ': ' + formatNumbersToCommaSeperated(this.y);
      }
    },
    title: null,
    yAxis: {
      title: {
        text: null
      },

    },
    xAxis: {
      categories: categories,
      title: {
        text: null
      },
      // lineColor: "#00C2E6",
      tickColor: "transparent"
    },
    legend: {
      enabled: false
    },
    plotOptions: {
      line: {
        width: 1,
        color: "#00C2E6",
        marker: {
          enabled: false
        },
      },
    },

    series: [
      {
        name: formatName(type),
        data: data,
      }
    ],
    credits: {
      enabled: false
    }
  }
}
const InfulencersSection = (props) => {
  return (
    <div className="section-inf" id="Influencer">
      <Row className="row-xs">
        <AccountOverview {...props} />
      </Row>
      <Row className="row-xs">
        <EngagementRateSection {...props} />
      </Row>

      <Row className="row-xs">
        {(!props.cloudTags || !props.cloudTags.data || !props.cloudTags.data.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.user_profile ||
          !props.accountOverviewDetails.user_profile.similar_users || !props.accountOverviewDetails.user_profile.similar_users.length) ?
          null :
          <>
            <SocialCloud {...props} />
            <LookAlikesSection {...props} />
          </>
        }
      </Row>

      <Row className="row-xs">
        <Col md="4">
          <Card className="card-content-custom">
            <CardBody>
              <CardTitle>Followers<span className={`rank-wrap ${props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.calculatePercentage('followers') ? (props.calculatePercentage('followers').indexOf("-") < 0 ? 'rank-up' : 'rank-down') : ''}`}>{props.calculatePercentage('followers')}</span> {props.calculatePercentage('followers') ? 'this month' : ''} </CardTitle>
              <div className="graph-wrap ptop15 ch-4">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions(props, 'followers')}
                />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="4">
          <Card className="card-content-custom ">
            <CardBody>
              <CardTitle>Following<span className={`rank-wrap ${props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.calculatePercentage('following') ? (props.calculatePercentage('following').indexOf("-") < 0 ? 'rank-up' : 'rank-down') : ''}`}>{props.calculatePercentage('following')}</span>  {props.calculatePercentage('following') ? 'this month' : ''}</CardTitle>
              <div className="graph-wrap ptop15 ch-4">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions(props, 'following')}
                />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="4">
          <Card className="card-content-custom">
            <CardBody>
              <CardTitle>Avg Likes<span className={`rank-wrap ${props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.calculatePercentage('avg_likes') ? (props.calculatePercentage('avg_likes').indexOf("-") < 0 ? 'rank-up' : 'rank-down') : ''}`}>{props.calculatePercentage('avg_likes')}</span>
                {props.calculatePercentage('avg_likes') ? 'this month' : ''}</CardTitle>
              <div className="graph-wrap ptop15 ch-4">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartOptions(props, 'avg_likes')}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {!props.accountOverviewDetails || !props.accountOverviewDetails.user_profile || !props.accountOverviewDetails.user_profile.recent_posts || !props.accountOverviewDetails.user_profile.recent_posts.length
        ? null
        :
        <Row>
          <EngagementsSpreadLastPosts {...props} />
        </Row>
      }

      <Row className="row-xs">
        {(!props.accountOverviewDetails || !props.accountOverviewDetails.user_profile || !props.accountOverviewDetails.user_profile.top_hashtags || !props.accountOverviewDetails.user_profile.top_hashtags.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.user_profile || !props.accountOverviewDetails.user_profile.top_mentions || !props.accountOverviewDetails.user_profile.top_mentions.length)
          ? null : <>
            <PopularHashtags {...props} />
            <PopularMentions {...props} />
          </>
        }

      </Row>

      <Row className="row-xs">
        {(!props.accountOverviewDetails || !props.accountOverviewDetails.user_profile || !props.accountOverviewDetails.user_profile.brand_affinity || !props.accountOverviewDetails.user_profile.brand_affinity.length) && (!props.accountOverviewDetails || !props.accountOverviewDetails.user_profile || !props.accountOverviewDetails.user_profile.interests || !props.accountOverviewDetails.user_profile.interests.length)
          ? null : <>
            <InfluencerBrandAffinity {...props} />
            <InfluencerInterests {...props} />
          </>
        }
      </Row>

    </div>
  )
}
export default InfulencersSection;