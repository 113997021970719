import { pdfReportsActionTypes } from "../constants";
import { COMMON_ERROR_MESSAGE } from "../../../actions";

const initialState = {
  loading: false,
  postAnalysisDetails: null,
  postStatusErrorMessage: null,
}
export default (state = initialState, { type, payload }) => {
  switch (type) {

    case pdfReportsActionTypes.getPostDataById.REQUEST:
      return {
        ...state,
        loading: true,
        postAnalysisDetails: null,
      }
    case pdfReportsActionTypes.getPostDataById.SUCCESS:
      return {
        ...state,
        loading: false,
        postAnalysisDetails: payload,
      }
    case pdfReportsActionTypes.getPostDataById.FAILURE:
      return {
        ...state,
        loading: false,
        postAnalysisDetails: null,
        postStatusErrorMessage: payload || COMMON_ERROR_MESSAGE,
      }

    case pdfReportsActionTypes.showLoading.REQUEST:
      return {
        ...state,
        loading: true,
      }
      case pdfReportsActionTypes.clearPostAnalysisErrorMsg.SUCCESS:
        return {
          ...state,
          postStatusErrorMessage: null,
        }
    case pdfReportsActionTypes.showLoading.SUCCESS:
      return {
        ...state,
        loading: false,
      }
    default: {
      return state
    }
  }
}
