import React from 'react'
import { Col, Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { formatNumbersToCommaSeperated, capitalize, showTitle } from '../../../actions';
import { RenderButton } from '../../../components';

const LookAlikesSection = (props) => {
  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Lookalikes</CardTitle>

          {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.similar_users && props.accountOverviewDetails.user_profile.similar_users.length ?
            <div className="card-content ch-3">
              <ListGroup className="card-list-items">
                {props.accountOverviewDetails && props.accountOverviewDetails.user_profile.similar_users.map((user, index) => {
                  if (index < 5) {
                    return <ListGroupItem key={index}><div className="avatar"><img src={user.picture} alt="profile pic" /></div>
                      <div id={`influencer_${user.fullname}`} title={showTitle(`influencer_${user.fullname}`, user.fullname)} className="list-content"> <a rel="noopener noreferrer" target="_blank" href={user.url}>@{user.username}</a>
                        <p>{capitalize(user.fullname)}</p>
                      </div>
                    </ListGroupItem>
                  }
                  return null;
                })}
              </ListGroup>
            </div>
            : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}

          {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.similar_users && props.accountOverviewDetails.user_profile.similar_users.length ?
            <div className="bottom">
              <Button color="link" onClick={() => props.toggleModal("lookAlikesModal")}>View More</Button>
              <Modal id="lookAlikesModalDownload" isOpen={props.state.modals.lookAlikesModal} toggle={() => props.toggleModal("lookAlikesModal")} >
                <ModalHeader toggle={() => props.toggleModal("lookAlikesModal")}>
                  <span className="tooltip-button-wrap"><span className="text-tooltip">Lookalikes</span>  <span href="#" id="lookAlikesModal" className="tooltip-icon"></span></span>
                  <UncontrolledTooltip placement="bottom" className="tooltip-content" target="lookAlikesModal" >
                    Hello world!
              </UncontrolledTooltip>
                  <RenderButton onClick={() => props.downloadPdf("lookAlikesModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
                </ModalHeader>
                <ModalBody >
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan="2">Influencer</th>
                        <th className="text-right">Engagements</th>
                        <th className="text-right">Followers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.similar_users && props.accountOverviewDetails.user_profile.similar_users.length ?
                        props.accountOverviewDetails.user_profile.similar_users.map((user, index) => {
                          return <tr key={index}>
                            <th scope="row" className="tb-media-left"><img src={user.picture} alt="profile pic" className="rounded-circle" /></th>
                            <td className="tb-head"><h4> <a rel="noopener noreferrer" target="_blank" href={user.url}>@{user.username}</a></h4>
                              <div className="country-text-wrap">

                                <div className="country-name">{user.fullname}</div>
                              </div>
                            </td>
                            <td className="text-right">{formatNumbersToCommaSeperated(user.engagements)}</td>
                            <td className="text-right">{formatNumbersToCommaSeperated(user.followers)}</td>
                          </tr>
                        }) : null}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
            : null}
        </CardBody>
      </Card>
    </Col>
  )
}

export default LookAlikesSection
