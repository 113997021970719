import React from 'react'
import { Row, Col, Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Badge, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip, Progress } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle } from '../../../../actions';
import { RenderButton } from '../../../../components';
import Highcharts from "highcharts";
import HC_map from "highcharts/modules/map";
import HighchartsReact from "highcharts-react-official";
import MAP_DATA from '@highcharts/map-collection/custom/world.geo.json';
import mapMarker from '../../../../img/map-marker.svg';
HC_map(Highcharts);
const LikesLocationByCity = (props) => {
  let data = props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_geo && props.postAnalysisDetails.audience_likers.data.audience_geo.cities ? props.postAnalysisDetails.audience_likers.data.audience_geo.cities.map((city, index) => ({
    name: city.name,
    lat: city.coords.lat,
    lon: city.coords.lon,

  })) : [];
  const mapOptions = {
    chart: {
      map: MAP_DATA,
      height: (3 / 4 * 100) + '%' // 4:3 ratio

    },
    mapNavigation: {
      enabled: false,
      buttonOptions: {
        verticalAlign: 'bottom'
      }
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '<b>{point.name}</b>'
    },

    title: {
      text: null
    },
    credits: {
      enabled: false
    },

    series: [
      {
        name: 'Basemap',
        borderColor: 'rgba(96, 96, 96, 0.4)',
        borderWidth: 1,
        nullColor: 'rgba(200, 200, 200, 0)',
        showInLegend: false
      }, {
        name: 'Separators',
        type: 'mapline',
        enableMouseTracking: false,
        color: '#101010',
        showInLegend: false
      }
      , {
        type: 'mappoint',
        showInLegend: false,
        dataLabels: {
          enabled: true,
        },
        marker: {
          symbol: `url(${mapMarker})`,
          states: {
            hover: {
              enabled: false,
            }
          }

        },
        name: 'Cities',
        data: data,
        maxSize: '12%',

      }
    ]
  };
  return (
    <Card className="card-content-custom">

      <CardBody>
        <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Location by City</span>
          <span href="#" id="followersLocationByCity" className="tooltip-icon"></span></span>
          <UncontrolledTooltip placement="bottom" className="tooltip-content" target="followersLocationByCity" >
            Hello world!
          </UncontrolledTooltip>
        </CardTitle>
        <Row className="row-xs">

          <Col sm="6" md="8">
            {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_geo && props.postAnalysisDetails.audience_likers.data.audience_geo.cities && props.postAnalysisDetails.audience_likers.data.audience_geo.cities.length
              ?
              <div className="graph-wrap text-left map">
                <HighchartsReact
                  highcharts={Highcharts}
                  constructorType={'mapChart'}
                  options={mapOptions}
                />
              </div>
              : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}

          </Col>

          <Col sm="6" md="4">
            <ListGroup flush className="country-list">
              {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_geo && props.postAnalysisDetails.audience_likers.data.audience_geo.cities ?
                props.postAnalysisDetails.audience_likers.data.audience_geo.cities.map((city, index) => {
                  if (index < 5) {
                    return <ListGroupItem key={index}>
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="country-text-wrap">
                          <div className="flag-img">{<img src={props.generateImgUrl('country', city.country && city.country.code)} alt="img" />}</div>
                          <div id={`likes_${city.name}`} title={showTitle(`likes_${city.name}`, city.name)} className="country-name">{capitalize(city.name)}</div>
                        </div>
                        <Badge className="text-grey" color="">{(city.weight * 100).toFixed(2) + '%'}</Badge>
                      </div>
                      <Progress value={(city.weight * 100).toFixed(2)} className="progress-bar-custom" />
                    </ListGroupItem>
                  }
                  return null;
                }
                ) : null}
            </ListGroup>

            {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_geo && props.postAnalysisDetails.audience_likers.data.audience_geo.cities && props.postAnalysisDetails.audience_likers.data.audience_geo.cities.length
              ?
              <div className="bottom">
                <Button color="link" onClick={() => props.toggleModal("likesLocationByCityModal")}>View More</Button>

                <Modal
                  id="likesLocationByCityModalDownload"
                  isOpen={props.state.modals.likesLocationByCityModal}
                  toggle={() => props.toggleModal("likesLocationByCityModal")}
                  >
                  <ModalHeader
                    toggle={() => props.toggleModal("likesLocationByCityModal")}>
                    <span className="tooltip-button-wrap"><span className="text-tooltip">Location by City</span><span href="#" id="likesLocationByCityModal" className="tooltip-icon"></span></span>
                    <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesLocationByCityModal" >
                      Hello world!
                  </UncontrolledTooltip>
                    <RenderButton onClick={() => props.downloadPdf("likesLocationByCityModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
                  </ModalHeader>
                  <ModalBody>

                    <Table className="modal-table">
                      <thead>
                        <tr>
                          <th>City</th>
                          <th className="text-right">Likes</th>
                          <th className="text-right">Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.postAnalysisDetails && props.postAnalysisDetails.audience_likers && props.postAnalysisDetails.audience_likers.data && props.postAnalysisDetails.audience_likers.data.audience_geo && props.postAnalysisDetails.audience_likers.data.audience_geo.cities ?
                          props.postAnalysisDetails.audience_likers.data.audience_geo.cities.map((city, index) =>
                            <tr key={index}>
                              <th scope="row"><div className="country-text-wrap">
                                <div className="flag-img">{<img src={props.generateImgUrl('country', city.country && city.country.code)} alt="img" />}</div>
                                <div className="country-name">{capitalize(city.name)}</div>
                              </div></th>
                              <td className="text-right">{formatNumbersToCommaSeperated((city.weight) * props.postAnalysisDetails.posts_profile.likes)}</td>
                              <td className="text-right">{(city.weight * 100).toFixed(2) + '%'}</td>
                            </tr>) :
                          <tr>
                            <td align="center" colSpan="3">No Record Found</td>
                          </tr>
                        }
                      </tbody>
                    </Table>
                  </ModalBody>
                </Modal>
              </div>
              : null}
          </Col>
        </Row>

      </CardBody>
    </Card>
  )
}

export default LikesLocationByCity
