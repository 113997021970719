import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle, showTooltip } from '../../../../actions';
import { RenderButton } from '../../../../components';
const LikesAudienceBrandAffinity = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Audience Brand Affinity</CardTitle>
        <div className="card-content ch-8">
          <ListGroup className="ba-list">
            {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_brand_affinity ?
              props.accountOverviewDetails.audience_likers.data.audience_brand_affinity.map((affinity, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index} className="d-flex align-items-center">
                    <div className="img-col-left"><img src={props.generateImgUrl('brand', affinity.id)} alt="img" /></div>
                    <div id={`likes_${affinity.name}`} title={showTitle(`likes_${affinity.name}`, affinity.name)} className="text-wrap">
                      {capitalize(affinity.name)}
                    </div>
                  </ListGroupItem>
                }
                return null
              }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}

          </ListGroup>
        </div>
        {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_brand_affinity ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("likesAudienceBrandAffinityModal")}>View More</Button>
            <Modal id="likesAudienceBrandAffinityModalDownload" isOpen={props.state.modals.likesAudienceBrandAffinityModal} toggle={() => props.toggleModal("likesAudienceBrandAffinityModal")} >
              <ModalHeader toggle={() => props.toggleModal("likesAudienceBrandAffinityModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Audience Brand Affinity</span>  <span href="#" id="likesAudienceBrandAffinityModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="likesAudienceBrandAffinityModal" >
                 {showTooltip('likesAudienceBrandAffinityModal')}
          </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("likesAudienceBrandAffinityModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>

                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Brand</th>
                      <th className="text-right">Followers</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails && props.accountOverviewDetails.audience_likers && props.accountOverviewDetails.audience_likers.data && props.accountOverviewDetails.audience_likers.data.audience_brand_affinity ?
                      props.accountOverviewDetails.audience_likers.data.audience_brand_affinity.map((affinity, index) =>
                        <tr key={index}>
                          <th scope="row">
                            <div className="country-text-wrap">
                              <div className="flag-img  brand-img"><img src={props.generateImgUrl('brand', affinity.id)} alt="img" /></div>
                              <div className="country-name">{capitalize(affinity.name)}</div>
                            </div>
                          </th>
                          <td className="text-right">{formatNumbersToCommaSeperated((affinity.weight) * props.accountOverviewDetails.user_profile.followers)}</td>
                          <td className="text-right">{formatNumbersToCommaSeperated((affinity.weight) * props.accountOverviewDetails.user_profile.avg_likes)}</td>
                          <td className="text-right">{(affinity.weight * 100).toFixed(2) + '%'}</td>
                        </tr>) :
                      <tr>
                        <td align="center" colSpan="4">No Record Found</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default LikesAudienceBrandAffinity;
