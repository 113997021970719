import { actionCreator, checkHttpStatus, COMMON_ERROR_MESSAGE, checkAuthTokenExpiry } from "../../../actions";
import { pdfReportsActionTypes, GET_POST_DATA_BY_SEARCH_URL } from "../constants";
import history from "../../../history";


export const getPostDataById = (postId, token, clearFields) => {
  return (dispatch) => {
    if (clearFields === true) {
      dispatch(actionCreator(pdfReportsActionTypes.getPostDataById.SUCCESS, null));
    } else {
      dispatch(actionCreator(pdfReportsActionTypes.getPostDataById.REQUEST));
      fetch(`${GET_POST_DATA_BY_SEARCH_URL}/${postId}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,

        }
      }).then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then(response => {
          if (response.success) {
            dispatch(actionCreator(pdfReportsActionTypes.getPostDataById.SUCCESS, response));
          }
          else {
            let postStatusErrorMessage = response.message || COMMON_ERROR_MESSAGE;
            dispatch(actionCreator(pdfReportsActionTypes.getPostDataById.FAILURE, postStatusErrorMessage));
            if (response.redirect === 'PAYMENT_PAGE') {
              history.push('/subscription');
            }
          }
        }).catch(error => {
          console.log(error)
          dispatch(actionCreator(pdfReportsActionTypes.getPostDataById.FAILURE));
          if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
            history.push('/');
          }
        })
    }

  }
}
export const clearPostAnalysisErrorMsg = () => {
  return (dispatch) => {
    dispatch(actionCreator(pdfReportsActionTypes.clearPostAnalysisErrorMsg.SUCCESS))
  }
}




export const showLoadingAction = (action) => {
  return (dispatch) => {
    if (action.toLowerCase() === 'start') {
      dispatch(actionCreator(pdfReportsActionTypes.showLoading.REQUEST));
    } else if (action.toLowerCase() === 'stop') {
      dispatch(actionCreator(pdfReportsActionTypes.showLoading.SUCCESS));
    }
  }
}

