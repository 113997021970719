import React from "react";
//import Footer from '../modules/footer/index';
import ReportsComponent from "../modules/reports/index";
import ErrorComponent from "../modules/error/index";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import history from "../history";
import Loader from "../loader";
import Alert from "react-s-alert";
import postAnalysisComponent from "../modules/postAnalysis";
import Header from "../modules/header";
class AppRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollY: 0
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll = () => {
    this.setState({
      scrollY: window.scrollY
    });
  };
  render() {
    return (
      <Router history={history}>
        {this.props.isReportsReducerLoading ? <Loader /> : null}
        <Header />

        <div id="app" className="content">
          <Switch>
            {/* <Route exact path="/" component={ErrorComponent} /> */}
            <Route exact path="/reports/:id" component={ReportsComponent} />
            <Route
              exact
              path="/postAnalysis/:id"
              component={postAnalysisComponent}
            />
            <Route path="*" component={ErrorComponent} />
          </Switch>
        </div>

        <Alert stack={{ limit: 3 }} />
      </Router>
    );
  }
}
const mapStateToProps = state => ({
  isReportsReducerLoading: state.reports.loading
});
export default connect(mapStateToProps, null)(AppRoutes);
