import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Badge, Progress, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle, showTooltip } from '../../../../actions';
import { RenderButton } from '../../../../components';
const FollowersLanguage = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Language</CardTitle>
        <div className="card-content ch-10">
          <ListGroup flush className="country-list with-progress">
            {props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_languages ?
              props.accountOverviewDetails.audience_followers.data.audience_languages.map((language, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index}>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="country-text-wrap">
                        <div id={`followers_${language.name}`} title={showTitle(`followers_${language.name}`, language.name)} className="country-name">{capitalize(language.name)}
                        </div>
                      </div>
                      <Badge className="text-grey" color="">{(language.weight * 100).toFixed(2) + '%'}</Badge>
                    </div>
                    <Progress value={(language.weight * 100).toFixed(2)} className="progress-bar-custom" />
                  </ListGroupItem>
                }
                return null;
              })
              : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
          </ListGroup>
        </div>
        {props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_languages ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("followersLanguageModal")}>View More</Button>
            <Modal id="followersLanguageModalDownload" isOpen={props.state.modals.followersLanguageModal} toggle={() => props.toggleModal("followersLanguageModal")} >
              <ModalHeader toggle={() => props.toggleModal("followersLanguageModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Language</span>  <span href="#" id="followersLanguageModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="followersLanguageModal" >
                  {showTooltip('followersLanguageModal')}
            </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("followersLanguageModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>

                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Language</th>
                      <th className="text-right">Followers</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Percentage</th>
                    </tr>
                  </thead>
                  <tbody>

                    {props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.audience_languages && props.accountOverviewDetails.audience_followers.data.audience_languages ?
                      props.accountOverviewDetails.audience_followers.data.audience_languages.map((language, index) =>
                        <tr key={index}>
                          <th scope="row"><div className="country-text-wrap">
                            <div className="country-name">{capitalize(language.name || 'Others')}</div>
                          </div></th>
                          <td className="text-right">{formatNumbersToCommaSeperated((language.weight) * props.accountOverviewDetails.user_profile.followers)}</td>
                          <td className="text-right">{formatNumbersToCommaSeperated((language.weight) * props.accountOverviewDetails.user_profile.avg_likes)}</td>
                          <td className="text-right">{(language.weight * 100).toFixed(2) + '%'}</td>
                        </tr>) :
                      <tr>
                        <td align="center" colSpan="4">No Record Found</td>
                      </tr>
                    }
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default FollowersLanguage
