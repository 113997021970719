import React from 'react'
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import InstaStories from 'react-insta-stories'
import { connect } from 'react-redux';
import { formatNumbersToUnits } from '../../../actions';
import HasNoStory from './hasNoStory';

class Stories extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initial: true,
    }
  }
  handlePrevious = () => {
    this.stories.previous();
  }
  handleNext = () => {
    this.stories.next();
  }
  componentDidUpdate() {
    // console.log('this.stories---', this.stories, 'this.props.stories---',this.props.stories);
    let storiesData = this.props.stories && this.props.stories.data;
    const stories = storiesData && storiesData.has_stories && storiesData.stories;
    if (stories && stories.length && stories[0].type === 'video' && this.state.initial) {
      setTimeout(() => this.stories.pause(), 200);
    }
  }

  render() {
    let storiesData = this.props.stories && this.props.stories.data;
    const stories = (storiesData && storiesData.has_stories && storiesData.stories.map((story, index) => ({
      url: story.source_url,
      type: story.type,
      header: {
        heading: story.time,
        // subheading: 'Posted 5h ago',
        // profileImage: 'https://picsum.photos/1000/1000'
      }
    })
    )) || [];

    return (
      <div className="section">
        {storiesData && storiesData.has_stories ?
          <>
            <div className="section-head text-center"><h2>Story Details</h2></div>
            <Row>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{formatNumbersToUnits(storiesData.estimated_reach)}</h2></div>
                    </div>
                    <CardTitle>Estimated Reach</CardTitle>
                  </CardBody>
                </Card>
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{formatNumbersToUnits(storiesData.back)}</h2></div>
                    </div>
                    <CardTitle>Estimated Backs / Rewatch</CardTitle>
                  </CardBody>
                </Card>
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{formatNumbersToUnits(storiesData.forwards)}</h2></div>
                    </div>
                    <CardTitle>Estimated Forwards</CardTitle>
                  </CardBody>
                </Card>
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{formatNumbersToUnits(storiesData.estimated_swipe_ups)}</h2></div>
                    </div>
                    <CardTitle>Estimated Swipe Ups</CardTitle>
                  </CardBody>
                </Card>  <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{Math.round(storiesData.stories_per_day)}</h2></div>
                    </div>
                    <CardTitle>Stories Per Day</CardTitle>
                  </CardBody>
                </Card>  <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{Math.round(storiesData.replies)}</h2></div>
                    </div>
                    <CardTitle>Estimated Replies</CardTitle>
                  </CardBody>
                </Card>

              </Col>
              <Col md="6">
                <Card className="card-content-custom">
                  <CardBody>
                    <div className="country-text-wrap">
                      <div className="country-name pt-2"><h2>{formatNumbersToUnits(storiesData.estimated_impressions)}</h2></div>
                    </div>
                    <CardTitle>Estimated Impressions</CardTitle>
                  </CardBody>
                </Card>
                <Card className="card-content-custom story-wrap">
                  {
                    storiesData.is_private ?
                      <CardBody>This account is private. Unable to retrieve stories.</CardBody>
                      : (!storiesData.has_stories ? <CardBody>This account currently has no stories to display.</CardBody> :
                       <>
                          <InstaStories
                            stories={stories}
                            storyStyles={{
                              width: '100%',
                              maxWidth: '100%',
                              maxHeight: '100%',
                              margin: 'auto',
                              objectFit: 'cover',

                            }}
                            // loop
                            ref={(input) => this.stories = input}
                    
                            height={635}
                          />
                          <div className="custom-nav-icons">
                            <button className="btn" onClick={this.handlePrevious}><i className="fa fa-chevron-left" aria-hidden="true"></i></button>

                            <button className="btn" onClick={this.handleNext}><i className="fa fa-chevron-right" aria-hidden="true"></i></button>
                          </div>
                       </>
                      )
                  }
                </Card>
              </Col>
            </Row>
          </>
          : <HasNoStory {...storiesData} />}
      </div>

    )
  }

}
const mapStateToProps = (state) => {
  return {
    stories: state.reports.stories,
  }
}
export default connect(mapStateToProps, null)(Stories);