import React from 'react'
import { Card, CardBody, CardTitle, ListGroup, ListGroupItem, Button, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { capitalize, formatNumbersToCommaSeperated, showTitle, showTooltip } from '../../../../actions';
import { RenderButton } from '../../../../components';
const FollowersNotableFollowers = (props) => {
  return (
    <Card className="card-content-custom">
      <CardBody>
        <CardTitle>Notable Users</CardTitle>
        <div className="card-content ch-9">
          <ListGroup className="card-list-items">
            {props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.notable_users ?
              props.accountOverviewDetails.audience_followers.data.notable_users.map((follower, index) => {
                if (index < 3) {
                  return <ListGroupItem key={index}><div className="avatar"><img src={follower.picture} alt="img" /></div>
                    <div id={`followers_${follower.fullname}`} title={showTitle(`followers_${follower.fullname}`, follower.fullname)} className="list-content"> <a rel="noopener noreferrer" target="_blank" href={follower.url}>{'@' + follower.username}</a>
                      <p>{capitalize(follower.fullname)}</p>
                    </div>
                  </ListGroupItem>
                }
                return null
              }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
          </ListGroup>
        </div>
        {props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.notable_users ?
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("followersNotableFiltersModal")}>View More</Button>

            <Modal id="followersNotableFiltersModalDownload" isOpen={props.state.modals.followersNotableFiltersModal} toggle={() => props.toggleModal("followersNotableFiltersModal")} >
              <ModalHeader toggle={() => props.toggleModal("followersNotableFiltersModal")}>
                <span className="tooltip-button-wrap"><span className="text-tooltip">Notable Users</span>  <span href="#" id="followersNotableFiltersModal" className="tooltip-icon"></span></span>
                <UncontrolledTooltip placement="bottom" className="tooltip-content" target="followersNotableFiltersModal" >
                 {showTooltip('followersNotableFiltersModal')}
              </UncontrolledTooltip>
                <RenderButton onClick={() => props.downloadPdf("followersNotableFiltersModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th colSpan="2">Influencer</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Followers</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails && props.accountOverviewDetails.audience_followers && props.accountOverviewDetails.audience_followers.data && props.accountOverviewDetails.audience_followers.data.notable_users ?
                      props.accountOverviewDetails.audience_followers.data.notable_users.map((follower, index) => {

                        return <tr key={index}>
                          <th scope="row" className="tb-media-left"><img src={follower.picture} alt="profile pic" className="rounded-circle" /></th>
                          <td className="tb-head"><h4> <a rel="noopener noreferrer" target="_blank" href={follower.url}>{'@' + follower.username}</a></h4>
                            <div className="country-text-wrap">
                              <div className="country-name">{follower.fullname}</div>
                            </div>
                          </td>
                          <td className="text-right">{formatNumbersToCommaSeperated(follower.engagements)}</td>
                          <td className="text-right">{formatNumbersToCommaSeperated(follower.followers)}</td>
                        </tr>

                      }) : <tr>
                        <td align="center" colSpan="3">No Record Found</td>
                      </tr>}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
          : null}
      </CardBody>
    </Card>
  )
}

export default FollowersNotableFollowers
