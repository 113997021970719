import Alert from 'react-s-alert';
import toolTipOptions from '../helpers/tooltip';
export const API_URL = process.env.REACT_APP_API_URL;
export const SIGNUP_SUCCESS_MESSAGE = 'You are registered and get access to the latest social media performance data';
export const COMMON_ERROR_MESSAGE = 'Something went wrong, please try again';
export const TAGS_ERROR_MSG = 'The search term you entered is not valid';
export const CAPTCHA_KEY = "bffbe37c-b98d-4ad1-ad6e-91f0a4e1cbd6"
const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';
export function checkHttpStatus(response) {
  if (response.status >= 200 && response.status < 204) {
    return response.json();
  } else if (response.status === 204) {
    return true;
  } else if (response.status >= 400 && response.status < 500) {
    return response.json();
  } else {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}
export const checkAuthTokenExpiry = (response) => {
  let valid = response;
  if (response.message && response.message === 'Failed to authenticate token.') {
    let error = new Error(response.message);
    error.message = 'You have been logged out. Please login again to continue using Tensor Social';
    error.statusCode = 401;
    throw error;
  }
  return valid;
}
export const showErrorMessage = (message, closeFunction, timeout = 'none') => {
  Alert.closeAll();
  Alert.error(message, {
    position: 'top',
    effect: 'slide',
    // offset: 80, 
    onClose: function () {
      return closeFunction()
    },
    timeout: timeout ? timeout : 2000,
  });
};
export const showSuccessMessage = (message, closeFunction, timeout = 'none') => {
  Alert.closeAll();
  Alert.success(message, {
    position: 'top',
    effect: 'slide',
    // offset: 80, 
    onClose: function () {
      return closeFunction()
    },
    timeout: timeout ? timeout : 2000,
  });
};

export function actionCreator(actionType, data) {
  return {
    type: actionType,
    payload: data,
  };
}
export function createRequestActionTypes(base) {
  return [REQUEST, SUCCESS, FAILURE].reduce((requestTypes, type) => {
    requestTypes[type] = `${base}_${type}`;
    return requestTypes;
  }, {});
}

export const createQueryParams = (searchObj) => {
  let queryParams = '?';
  let queryArray = Object.keys(searchObj).map((key, index) => `${key}=${searchObj[key]}`);
  queryParams = queryParams + (queryArray.length ? queryArray.join('&') : '');
  return queryParams;
}
export const formatNumbersToCommaSeperated = (number) => {
  return number && Math.round(number).toLocaleString().split(',').join(', ');
}
export const showTooltip = (id)=>{
  return (toolTipOptions.find(item=> item.id === id) &&  toolTipOptions.find(item=> item.id === id).value ) || ''
}
export const formatNumbersToUnits = (number) => {

  if (number && number.toLocaleString() && number.toLocaleString().split(',').length) {
    switch (number.toLocaleString().split(',').length) {
      case 1:
        return number.toLocaleString();
      case 2:
        return number.toLocaleString().split(',')[0] + 'K';
      case 3:
        return number.toLocaleString().split(',')[0] + '.' + parseInt(number.toLocaleString().split(',')[1][0]) + 'M';
      case 4:
        return number.toLocaleString().split(',')[0] + + '.' + parseInt(number.toLocaleString().split(',')[1][0]) + 'B';
      default:
        return ''
    }
  };
  return 0;
}

export const capitalize = (name) => {
  return name && (name.split(' ').map((word, index) => word.slice(0, 1).toUpperCase() + word.slice(1).toLowerCase())).join(' ');
}
export const titlelize = (name) => {
  return name && (name.split(' ').map((word, index) => ((index < 1 ? word.slice(0, 1).toUpperCase() : word.slice(0, 1)) + word.slice(1).toLowerCase())).join(' '));
}
export const formatName = (name) => {
  let formatedName = name && name.replace(/[A-Z]|-|_/, ' ');
  if (formatedName) {
    formatedName = capitalize(formatedName);
  }
  return formatedName;
}
export const privateHeaders = (getState) => {
  return {
    'Content-Type': 'application/json',
    'Authorization': getState().login.authToken,

  }
}


export const showTitle = (id, name) => (
  document.getElementById(id) && document.getElementById(id).offsetWidth < document.getElementById(id).scrollWidth ? capitalize(name) : ''
)

