import React from 'react'
import { Col, Card, CardBody, CardTitle, UncontrolledTooltip } from 'reactstrap';
import { formatNumbersToUnits, capitalize, showTitle, showTooltip } from '../../../actions';
const AccountOverview = (props) => {
  return (
    <>
      <Col sm="3">
        <Card className="card-content-custom ch-1">
          <CardBody>
            <h2>{formatNumbersToUnits(props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.followers)}<span className={`rank-wrap ${props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.calculatePercentage('followers') ? (props.calculatePercentage('followers').indexOf("-") < 0 ? 'rank-up' : 'rank-down') : ''}`}>{props.calculatePercentage('followers')}</span></h2>
            <CardTitle>Followers</CardTitle>


          </CardBody>
        </Card>
      </Col>
      <Col sm="3">
        <Card className="card-content-custom ch-1">
          <CardBody>
            <h2>{formatNumbersToUnits(props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.avg_likes)}<span className={`rank-wrap ${props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.calculatePercentage('avg_likes') ? (props.calculatePercentage('avg_likes').indexOf("-") < 0 ? 'rank-up' : 'rank-down') : ''}`}>{props.calculatePercentage('avg_likes')}</span></h2>
            <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Avg Likes</span>  <span id="avgLikes" className="tooltip-icon"></span></span>
              <UncontrolledTooltip className="tooltip-content" target="avgLikes" >
                {showTooltip('avgLikes')}
                </UncontrolledTooltip>
            </CardTitle>

          </CardBody>
        </Card>
      </Col>
      {
        props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.geo
          ?
          <Col sm="3">

            <Card className="card-content-custom ch-1">
              <CardBody>
                <div className="country-text-wrap">
                  <div className="location-card-flag">
                    <img src={props.generateImgUrl('country', props.accountOverviewDetails.user_profile.geo.country.code)} alt="img" />
                  </div>
                  <div className="country-name pt-2" title={showTitle("locationName", (props.accountOverviewDetails.user_profile.geo.city && props.accountOverviewDetails.user_profile.geo.city.name) || (props.accountOverviewDetails.user_profile.geo.country && props.accountOverviewDetails.user_profile.geo.country.name))}><h2 id="locationName">{capitalize((props.accountOverviewDetails.user_profile.geo.city && props.accountOverviewDetails.user_profile.geo.city.name) || (props.accountOverviewDetails.user_profile.geo.country && props.accountOverviewDetails.user_profile.geo.country.name))}</h2></div>

                </div>

                <CardTitle>Location</CardTitle>
              </CardBody>
            </Card>
          </Col>
          : null
      }
      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.paid_post_performance ?
        <Col sm="3">
          <Card className="card-content-custom ch-1">
            <CardBody>
              <h2>{(props.accountOverviewDetails.user_profile.paid_post_performance * 100).toFixed(2) + '%'}</h2>
              <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Paid Post Performance</span>  <span id="paidPostTooltip" className="tooltip-icon"></span></span>
                <UncontrolledTooltip className="tooltip-content" target="paidPostTooltip" >
                  Hello world!
                </UncontrolledTooltip>
              </CardTitle>
            </CardBody>
          </Card>
        </Col>
        : null}

    </>
  )
}
export default AccountOverview;