import { actionCreator, createQueryParams, checkHttpStatus, COMMON_ERROR_MESSAGE, checkAuthTokenExpiry } from "../../../actions";
import { reportsActionTypes, GET_ACCOUNT_OVERVIEW_URL, STORY_ANALYSIS_URL, GET_CLOUD_TAGS } from "../constants";
import history from "../../../history";

export const getAccountOverview = (reportId, token, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(actionCreator(reportsActionTypes.getAccountOverview.SUCCESS, null));
      dispatch(getStoryAnalysis(null, null, clearFields))
    } else {
      dispatch(actionCreator(reportsActionTypes.getAccountOverview.REQUEST));
      fetch(`${GET_ACCOUNT_OVERVIEW_URL}/${reportId}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }

      }).then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then(response => {
          if (response.success) {
            dispatch(actionCreator(reportsActionTypes.getAccountOverview.SUCCESS, response));
            let storyObj = {
              user: response.user_profile && response.user_profile.username,
              follower_count: response.user_profile && response.user_profile.followers
            }
            dispatch(getStoryAnalysis(storyObj, token));
            let cloudSearchbj = { tag: `@${response.user_profile.username}` };
            dispatch(getCloudTags(cloudSearchbj, token));
          } else {
            let clearFields = true;
            dispatch(getCloudTags(null, token, clearFields));
            let reportsErrorMessage = response.message || COMMON_ERROR_MESSAGE;
            dispatch(actionCreator(reportsActionTypes.showReportsErrorMessage.SUCCESS, reportsErrorMessage));
            if (response.redirect === 'PAYMENT_PAGE') {
              history.push('/subscription');
            } else {
              history.push("/");
            }

          }

        }).catch(error => {

          let reportsErrorMessage = COMMON_ERROR_MESSAGE;
          dispatch(actionCreator(reportsActionTypes.showReportsErrorMessage.SUCCESS, reportsErrorMessage));
          dispatch(actionCreator(reportsActionTypes.getAccountOverview.FAILURE));
          if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
            history.push("/");
          }
        })
    }

  }
}
export const getCloudTags = (searchObj, token, clearFields) => {
  return (dispatch, getState) => {
    if (clearFields === true) {
      dispatch(actionCreator(reportsActionTypes.getCloudTags.SUCCESS, null));
    } else {
      let queryParams = createQueryParams(searchObj);
      dispatch(actionCreator(reportsActionTypes.getCloudTags.REQUEST));
      fetch(`${GET_CLOUD_TAGS}${queryParams}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }

      }).then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response))
        .then(response => {
          dispatch(actionCreator(reportsActionTypes.getCloudTags.SUCCESS, response));
        }).catch(error => {
          dispatch(actionCreator(reportsActionTypes.getCloudTags.FAILURE));
          if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
            // dispatch(signOut(error));
          }
        })
    }
  }
}
// export const getInfluencerProfileDataBySearch = (postData, clearFields, userId) => {
//   return (dispatch, getState) => {
//     if (clearFields === true) {
//       dispatch(actionCreator(reportsActionTypes.getInfluencerProfileDataBySearch.SUCCESS, null));
//     } else {
//       dispatch(actionCreator(reportsActionTypes.getInfluencerProfileDataBySearch.REQUEST));
//       fetch(GET_INFLUENCER_PROFILE_DATA_BY_SEARCH, {
//         method: 'POST',
//         headers: privateHeaders(getState),
//         body: JSON.stringify(postData)
//       }).then(checkHttpStatus)
//         .then((response) => checkAuthTokenExpiry(response))
//         .then(response => {
//           if (response.success && response.report_info && response.report_info.report_id) {
//             dispatch(actionCreator(reportsActionTypes.getInfluencerProfileDataBySearch.SUCCESS, response));
//             let cloudSearchbj = { tag: `@${response.user_profile.username}` };
//             let storyObj = {
//               user: response.user_profile && response.user_profile.username,
//               follower_count: response.user_profile && response.user_profile.followers
//             }
//             dispatch(getCloudTags(cloudSearchbj));
//             dispatch(getStoryAnalysis(storyObj));
//             window.open(`/reports/${response.report_info.report_id}`, '_blank');
//           } else {

//             let clearFields = true;
//             dispatch(getCloudTags(null, clearFields));
//             let reportsErrorMessage = response.error_message || response.message || COMMON_ERROR_MESSAGE;
//             dispatch(actionCreator(reportsActionTypes.showReportsErrorMessage.SUCCESS, reportsErrorMessage));
//             if (response.redirect === 'PAYMENT_PAGE') {
//               history.push('/subscription');
//             }
//           }


//         }).catch(error => {
//           let reportsErrorMessage = COMMON_ERROR_MESSAGE;
//           dispatch(actionCreator(reportsActionTypes.showReportsErrorMessage.SUCCESS, reportsErrorMessage));
//           dispatch(actionCreator(reportsActionTypes.getInfluencerProfileDataBySearch.FAILURE));
//           if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
//             // dispatch(signOut(error));
//           }
//         })
//     }

//   }
// }
export const clearReportsErrorMsg = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(reportsActionTypes.clearReportsErrorMessage.SUCCESS));
  }
}


export const showLoadingAction = (action) => {
  return (dispatch, getState) => {
    if (action.toLowerCase() === 'start') {
      dispatch(actionCreator(reportsActionTypes.showLoading.REQUEST));
    } else if (action.toLowerCase() === 'stop') {
      dispatch(actionCreator(reportsActionTypes.showLoading.SUCCESS));
    }
  }
}

export const getStoryAnalysis = (storyObj, token, clearFields) => {

  return (dispatch, getState) => {

    if (clearFields === true) {
      dispatch(actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS), null)
    } else {
      dispatch(actionCreator(reportsActionTypes.getStoryAnalysis.REQUEST));
      fetch(`${STORY_ANALYSIS_URL}?username=${storyObj.user}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }

      }).then(checkHttpStatus)
        .then((response) => checkAuthTokenExpiry(response)).then(response => {
          if (response.success) {
            dispatch(actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS, response));
          } else {
            dispatch(actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS, null))
          }
        }).catch(error => {

          dispatch(actionCreator(reportsActionTypes.getStoryAnalysis.SUCCESS, null));
          if (error.statusCode && error.statusCode >= 400 && error.statusCode <= 500) {
            // dispatch(signOut(error));
          }
        })
    }

  }
}
export const clearSubscriptionErrorAction = () => {
  return (dispatch, getState) => {
    dispatch(actionCreator(reportsActionTypes.clearSubscriptionError.SUCCESS));
  }
}
export const showPlansErrorInReports = (errorMsg) => {
  return (dispatch, getState) => {
    dispatch(actionCreator(reportsActionTypes.showSubscriptionError.SUCCESS, errorMsg));
  }
}