import React from 'react'
import { Col, Card, CardBody, CardTitle, ListGroup, ListGroupItem, Modal, ModalHeader, UncontrolledTooltip, ModalBody, Table } from 'reactstrap';
import { RenderButton } from '../../../components';
import { capitalize, showTitle, showTooltip } from '../../../actions';
const InfluencerInterests = (props) => {
  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Influencer Interests</CardTitle>
          <ListGroup className="ba-list ch-6">
            {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.interests ? props.accountOverviewDetails.user_profile.interests.map((interest, index) => {
              if (index < 3) {
                return (
                  <ListGroupItem key={index} className="d-flex align-items-center">
                    <div className="mr-3">
                      <img src={props.generateImgUrl('interest', interest.id)} alt="img" /></div>
                    <div className="text-wrap">
                      {interest.name}
                    </div></ListGroupItem>
                )
              }
              return null;
            }) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}

          </ListGroup>
          {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.interests ?
            <div className="bottom">
              <RenderButton color="link" onClick={() => props.toggleModal("influencersInterestsModal")}>View More</RenderButton>
              <Modal id="influencersInterestsModalDownload" isOpen={props.state.modals.influencersInterestsModal} toggle={() => props.toggleModal("influencersInterestsModal")} >
                <ModalHeader toggle={() => props.toggleModal("influencersInterestsModal")}>
                  <span className="tooltip-button-wrap"><span className="text-tooltip">Influencer Interests</span>  <span href="#" id="influencersInterestsModal" className="tooltip-icon"></span></span>
                  <UncontrolledTooltip placement="bottom" className="tooltip-content" target="influencersInterestsModal" >
                    {showTooltip('influencersInterestsModal')}
          </UncontrolledTooltip>
                  <RenderButton onClick={() => props.downloadPdf("influencersInterestsModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
                </ModalHeader>
                <ModalBody>

                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>Interest</th>

                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.interests ? props.accountOverviewDetails.user_profile.interests.map((interest, index) => (
                        <tr key={index}>
                          <th colSpan={2} scope="row"><div className="country-text-wrap">
                            <div className="flag-img brand-img"><img src={props.generateImgUrl('interest', interest.id)} alt="img" /></div>
                            <div id={`influencer_${interest.name}`} title={showTitle(`influencer_${interest.name}`, interest.name)} className="country-name">{capitalize(interest.name)}</div>
                          </div></th>
                        </tr>))
                        :
                        <tr>
                          <td align="center" colSpan="2">No Record Found</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
            : null}
        </CardBody>
      </Card>
    </Col>
  )
}

export default InfluencerInterests
