import React from 'react'
import { Col, Card, CardBody, CardTitle, Button, Progress, Modal, ModalHeader, ModalBody, Table, UncontrolledTooltip } from 'reactstrap';
import { formatNumbersToCommaSeperated, formatNumbersToUnits, showTooltip } from '../../../actions';
import { RenderButton } from '../../../components';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import similarAccountPic from '../../../img/avatar.svg';
const EngagementRateSection = (props) => {
  const tickPositions = [];
  let userProfileIndicator = null;
  const userProfilePicPath = props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.picture ? props.accountOverviewDetails.user_profile.picture : null;
  if (props.accountOverviewDetails && props.accountOverviewDetails.extra && props.accountOverviewDetails.extra.engagement_rate_histogram) {
    props.accountOverviewDetails.extra.engagement_rate_histogram.map((eng, index) => {
      if (eng.median) {
        return tickPositions.push(index + 1)
      }
      return null
    })
  };
  const data = props.accountOverviewDetails && props.accountOverviewDetails.extra && props.accountOverviewDetails.extra.engagement_rate_histogram ? props.accountOverviewDetails.extra.engagement_rate_histogram.map((eng, index) => {
    let isUserSlot = false;

    if (eng.median) {
      isUserSlot = false;
      userProfileIndicator = null;
    }
    else if (eng.max && !eng.min && props.accountOverviewDetails.user_profile.engagement_rate <= eng.max) {
      isUserSlot = true;
      userProfileIndicator = (index * 2 * props.state.changedLabelWidth) + (props.state.changedLabelWidth - 2);
    } else if (!eng.max && eng.min && props.accountOverviewDetails.user_profile.engagement_rate >= eng.max) {
      isUserSlot = true;
      userProfileIndicator = (index * 2 * props.state.changedLabelWidth) + (props.state.changedLabelWidth - 2);
    } else if (eng.max && eng.min && (props.accountOverviewDetails.user_profile.engagement_rate >= eng.min && props.accountOverviewDetails.user_profile.engagement_rate <= eng.max)) {
      isUserSlot = true;
      userProfileIndicator = (index * 2 * props.state.changedLabelWidth) + (props.state.changedLabelWidth - 2);
    }
    return {
      x: index + 1,
      id: `tick_${index + 1}`,
      xAxisLabelName: eng.median ? '<b>Similar Accounts</b>' : '',
      isUserSlot: isUserSlot,
      color: '#f0f0f6',
      y: eng.total,
      name: !eng.min ? `<${(eng.max * 100).toFixed(2)}%` : (!eng.max ? `>${(eng.min * 100).toFixed(2)}%` : `${(eng.min * 100).toFixed(2)}-${(eng.max * 100).toFixed(2)}%`),
    }
  }
  ) : [];
  const chartOptions = {
    chart: {
      height: 200,
      animation: false
    },
    title: {
      text: ''
    },
    xAxis: {
      lineColor: 'transparent',
      lineWidth: 35,
      tickPositions: tickPositions,
      labels:
      {
        enabled: true,
        formatter: function () {
          return data.find((eng) => eng.x === this.value) && data.find((eng) => eng.x === this.value).xAxisLabelName;
        }

      },
    },
    yAxis: {
      lineWidth: 0,
      gridLineWidth: 0,
      labels:
      {
        enabled: false
      },
      stackLabels: {
        enabled: true,
        useHTML: true,
        formatter: function () {
          return data.find((eng) => eng.y === this.total) && data.find((eng) => eng.y === this.total).xAxisLabelName !== '' ? `<span class="img-graph-bg"><img width="50px" src=${similarAccountPic} /></span>` : (data.find((eng) => eng.y === this.total).isUserSlot ? `<span class="img-graph-bg"><img width="50px" src=${userProfilePicPath} /></span>` : '');
        }
      },

      title: {
        text: null
      }
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        animation: false,
      }
    },
    tooltip: {
      enabled: true,
      outside: true,
      formatter: function () {
        return `<b>${this.point.name}<br/>${formatNumbersToUnits(this.y)} Accounts`;
      }
    },
    legend: {
      enabled: false
    },

    credits: {
      enabled: false
    },
    series: [{
      type: 'column',
      name: 'Max',
      states: {
        hover: {
          color: '#ddd'
        }
      },
      data: data,
    }]
  }
let div1Width = props.accountOverviewDetails && props.accountOverviewDetails.user_profile && isFinite(props.accountOverviewDetails.user_profile.trending_analysis) && props.accountOverviewDetails.user_profile.trending_analysis<0 ? 50-(Math.abs(props.accountOverviewDetails.user_profile.trending_analysis*100)/2 )+ '%': 50+(Math.abs(props.accountOverviewDetails.user_profile.trending_analysis*100)/2) + '%';
let div2Width = props.accountOverviewDetails && props.accountOverviewDetails.user_profile && isFinite(props.accountOverviewDetails.user_profile.trending_analysis) && props.accountOverviewDetails.user_profile.trending_analysis<0 ? 100- Math.abs(50-Math.abs(props.accountOverviewDetails.user_profile.trending_analysis*100)/2)+ '%': 100-(50+(Math.abs(props.accountOverviewDetails.user_profile.trending_analysis*100)/2)) + '%';
// console
  return (
    <>
      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && isFinite(props.accountOverviewDetails.user_profile.trending_analysis) ?
        <Col sm="6">
          <Card className="card-content-custom ch-356">
            <CardBody>

              <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Trending Analysis</span>  <span id="paidPostTooltip" className="tooltip-icon"></span></span>
                <UncontrolledTooltip className="tooltip-content" target="paidPostTooltip" >
                 {showTooltip('paidPostTooltip')}
                </UncontrolledTooltip>
              </CardTitle>
              <h2>{props.accountOverviewDetails.user_profile.trending_analysis}<span className={props.accountOverviewDetails.user_profile.trending_analysis > 0 ? 'rank-wrap rank-up' : (props.accountOverviewDetails.user_profile.trending_analysis < 0 ? 'rank-wrap rank-down' : 'rank-wrap rank-neutral')}>{props.accountOverviewDetails.user_profile.trending_analysis > 0 ? "Account is trending up" : (props.accountOverviewDetails.user_profile.trending_analysis < 0 ? "Account is trending down" : 'Account is neutral')}</span></h2>
              <div className="pt-90">
                <div className="trending-analysis-labels">
                  <span>-1</span>
                  <span>0</span>
                  <span>1</span>
                </div>
                <div className="progress trending-analysis-value">
                  
                  <div className="trending-overlap-div" style={{  minWidth: div1Width }}>

                  </div>
                  <div className="trending-overlap-transparent-div" style={{   minWidth: '2px' }}>

                  </div>
                  <div className="trending-overlap-div" style={{minWidth: div2Width}}></div>


                  <div className="progress-bar trending-analysis-progress-bar" role="progressbar" style={{ width: "100%" }}>
                  </div>
                </div>
                <div className="progress trending-analysis-progress">
                  <div id = "progress-1" className="progress-bar trending-analysis-progress-bar" role="progressbar" style={{ width: "100%" }}>
                  </div>
                </div>
                <div className="trending-analysis-labels">
                  <span>Bad</span>
                  <span>Normal</span>
                  <span>Good</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        : null}
        {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.engagement_rate ? 
      <Col sm="6">
        <Card className="card-content-custom ">
          <CardBody>
            <h2 title="Hello World">{props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.engagement_rate
              ? (props.accountOverviewDetails.user_profile.engagement_rate * 100).toFixed(2) + '%' : ''}</h2>
            <CardTitle><span className="tooltip-button-wrap"><span className="text-tooltip">Engagement Rate</span>  <span id="engagementTooltip" className="tooltip-icon"></span></span>
              <UncontrolledTooltip className="tooltip-content" target="engagementTooltip" >
                {showTooltip('engagementTooltip')}
                </UncontrolledTooltip>
            </CardTitle>

            <div id="customChart" className="graph-wrap card-content position-relative ch-200">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
              />

              {props.accountOverviewDetails && props.accountOverviewDetails.extra && props.accountOverviewDetails.extra.engagement_rate_histogram ? (
                <Progress value={(userProfileIndicator / props.state.containerWidth) * 100} className={tickPositions.length ? "graph-progress bottom-44" : "graph-progress"} />) : null}
            </div>
            <div className="bottom">
              <Button color="link" onClick={() => props.toggleModal("engagementRateModal")}>View More</Button>

              <Modal id="engagementRateModalDownload" isOpen={props.state.modals.engagementRateModal} toggle={() => props.toggleModal("engagementRateModal")} >
                <ModalHeader toggle={() => props.toggleModal("engagementRateModal")}>
                  <span className="tooltip-button-wrap"><span className="text-tooltip">Engagement Rate</span><span href="#" id="engagementRateModal" className="tooltip-icon"></span></span>
                  <UncontrolledTooltip placement="bottom" className="tooltip-content" target="engagementRateModal" >
                   {showTooltip('engagementRateModal')}
                    </UncontrolledTooltip>
                  <RenderButton onClick={() => props.downloadPdf("engagementRateModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
                </ModalHeader>
                <ModalBody  >
                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan="2">Engagement rate</th>
                        <th className="text-right">Accounts</th>
                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.extra && props.accountOverviewDetails.extra.engagement_rate_histogram && props.accountOverviewDetails.extra.engagement_rate_histogram.length ?
                        props.accountOverviewDetails.extra.engagement_rate_histogram.map((eng, index) => (<tr key={index}>
                          <th scope="row">{!eng.min ? `<${(eng.max * 100).toFixed(2)}%` : (!eng.max ? `>${(eng.min * 100).toFixed(2)}%` : `${(eng.min * 100).toFixed(2)}-${(eng.max * 100).toFixed(2)}%`)}</th>
                          <td className="text-left">&nbsp;</td>
                          <td className="text-right">{formatNumbersToCommaSeperated(eng.total)}</td>
                        </tr>))
                        : <tr>
                          <td align="center" colSpan="3">No Record Found</td>
                        </tr>}
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
          </CardBody>
        </Card>
      </Col>
      : null}
    </>
  )
}

export default EngagementRateSection;
