import React from 'react'
import { Col, Card, CardBody, CardTitle } from 'reactstrap';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import wordcloud from 'highcharts/modules/wordcloud';
wordcloud(Highcharts);
const chartOptions = (props) => {
  let data = props.cloudTags && props.cloudTags.data ? props.cloudTags.data.map((tag) => ({
    name: tag.tag.replace('#', ''),
    weight: parseFloat(tag.font_size.split(/px/i)[0]),
    color: tag.color

  })) : [];
  return {
    chart: {
      height: 298,

    },
    series: [{
      rotation: {
        from: 90,
        to: 90,

      },
      type: 'wordcloud',
      data: data
    }],
    title: {
      text: null
    },
    tooltip: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
          animation: false
      }
  },
  }
}
const SocialCloud = (props) => {
  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Relevant Topics&#xAE; </CardTitle>
          {props.cloudTags && props.cloudTags.data && props.cloudTags.data.length ?
            <div className="card-content ts-tag-clouds-in">
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions(props)}
              />
            </div>
            : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
        </CardBody>
      </Card>
    </Col>
  )
}

export default SocialCloud
