import React from 'react'
import { formatNumbersToUnits, formatNumbersToCommaSeperated } from '../../../actions';
import { Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, Table } from 'reactstrap';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import moment from 'moment';
import { RenderButton } from '../../../components';
import borderRadius from 'highcharts-border-radius';
borderRadius(Highcharts);
const engagementSpreadChartOptions = (props) => ({
  chart: {
    type: "column",
    height: 200,
  },
  title: null,
  yAxis: {
    title: {
      text: null
    },
  },
  tooltip: {
    formatter: function () {
      return '<b>' + this.series.name + '</b><br/>' +
        this.point.month + ': ' + formatNumbersToCommaSeperated(this.y);
    }
  },
  xAxis: {
    title: {
      text: null
    },
    type: 'category',
    tickColor: "transparent",
    categories: props.monthCategories,

  },
  legend: {
    enabled: false
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      
      pointPadding: 0,
      groupPadding: 0,
      
      //       borderWidth: 0,
      //       shadow: false
  }, column:{
    pointWidth: window.innerWidth < 1024 ? 15 : 20,
  }
  },

  series: [

    {
      name: "Likes",
      color: "#00C2E6",
      
      data: props.recentPostsLikesData,
      borderRadiusTopLeft: 10,
      borderRadiusTopRight: 10,
    },
    {
      name: "Comments",
      color: "#F3211D",
      data: props.recentPostsCommentsData,
      // pointWidth: window.innerWidth < 1024 ? 15 : 20,
    }

  ],
  credits: {
    enabled: false
  }
})
const EngagementsSpreadLastPosts = (props) => {
  return (
    <Col sm="12">
      <Card className="card-content-custom block-last-posts">
        <CardBody>
          <CardTitle>Engagements spread for last posts
          <ul className="like-comment">
              <li className="like">Likes</li>
              <li className="comment">Comments</li>
            </ul>
          </CardTitle>
          <div className="graph-wrap ptop15">
            <HighchartsReact
              highcharts={Highcharts}
              options={engagementSpreadChartOptions(props)}
            />
          </div>
          <div className="bottom">
            <Button color="link" onClick={() => props.toggleModal("engagementSpreadForLastPostsModal")}>View More</Button>
            <Modal id="engagementSpreadForLastPostsModalDownload" isOpen={props.state.modals.engagementSpreadForLastPostsModal} toggle={() => props.toggleModal("engagementSpreadForLastPostsModal")} >
              <ModalHeader toggle={() => props.toggleModal("engagementSpreadForLastPostsModal")}>
                Engagements spread for last posts
            <RenderButton onClick={() => props.downloadPdf("engagementSpreadForLastPostsModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
              </ModalHeader>
              <ModalBody>
                <Table className="modal-table">
                  <thead>
                    <tr>
                      <th>Post date</th>
                      <th className="text-right">Likes</th>
                      <th className="text-right">Comments</th>
                      <th className="text-right">Post</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.recent_posts && props.accountOverviewDetails.user_profile.recent_posts.length ? props.accountOverviewDetails.user_profile.recent_posts.map((post, index) => <tr key={index}>
                      <th scope="row">{moment(post.created).format('MMMM DD, YYYY, HH:mm:ss')}</th>
                      <td className="text-right">{formatNumbersToUnits(post.stat.likes)}</td>
                      <td className="text-right">{formatNumbersToUnits(post.stat.comments)}</td>
                      <td className="text-right"><a rel="noopener noreferrer" target="_blank" href={post.link}>View Post</a></td>
                    </tr>) : <tr>
                        <td colSpan="4" align="center">No Record Found</td>
                      </tr>}
                  </tbody>
                </Table>
              </ModalBody>
            </Modal>
          </div>
        </CardBody>
      </Card>
    </Col>
  )
}

export default EngagementsSpreadLastPosts;