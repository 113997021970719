/* eslint-disable no-loop-func */
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAccountOverview, showLoadingAction, clearReportsErrorMsg, getCloudTags, clearSubscriptionErrorAction } from './actions/index';

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import Proj4 from 'proj4'
import ReportSection from './reports';
import { showErrorMessage } from '../../actions';
// import { downloadReportPdfAction } from '../userProfile/actions/index';
import moment from 'moment';

class Reports extends Component {
  constructor(props) {
    super(props);
    window.proj4 = Proj4;
    this.state = {
      activeTab: '0',
      activeMainTab: '0',
      activePostTab: '0',
      accountOverviewDetails: null,
      containerWidth: 486,
      changedLabelWidth: 12,
      showStackLabels: true,
      username: '',


      modals: {
        engagementRateModal: false,
        lookAlikesModal: false,
        engagementSpreadForLastPostsModal: false,
        popularHashModal: false,
        popularAtTheRateModal: false,
        influencersBrandAffinityModal: false,
        influencersInterestsModal: false,
        followersGenderSplitModal: false,
        followersAgeAndGenderSplitModal: false,
        followersLocationByCityModal: false,
        followersLocationByCountryModal: false,
        followersEthnicityModal: false,
        followersLanguageModal: false,
        followersAudienceBrandAffinityModal: false,
        followersAudienceInterestsModal: false,
        followersAudienceLookAlikesModal: false,
        followersNotableFiltersModal: false,
        likesGenderSplitModal: false,
        likesAgeAndGenderSplitModal: false,
        likesLocationByCityModal: false,
        likesLocationByCountryModal: false,
        likesEthnicityModal: false,
        likesLanguageModal: false,
        likesAudienceBrandAffinityModal: false,
        likesAudienceInterestsModal: false,
        likesAudienceLookAlikesModal: false,
        likesNotableFiltersModal: false,
      }
    };
  }
  componentDidMount() {
    if (window.location.href.trim() !== '') {
      let url = window.location.href.trim();

      let reportId = url.split('/?')[0] && url.split('/?')[0].split('/').pop();
      let token = url.split('/?token=').pop();
      if (reportId && token) {
        this.props.getAccountOverview(reportId, token);
      }

    }
    if (document.getElementById("Audience") && window.scrollY < document.getElementById("Audience").offsetTop) {
      this.setState({
        activeMainTab: '0',
      })
    } else if (document.getElementById("Audience") && window.scrollY >= document.getElementById("Audience").offsetTop && window.scrollY < document.getElementById("Posts").offsetTop) {
      this.setState({
        activeMainTab: '1',
      })
    } else if (document.getElementById("Posts") && window.scrollY > document.getElementById("Posts").offsetTop) {
      this.setState({
        activeMainTab: '2',
      })
    }

    if (window.innerWidth <= 1024) {
      this.setState({
        showStackLabels: false,
      })
    } else {
      this.setState({
        showStackLabels: true,
      })
    }
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);
  }
  static getDerivedStateFromProps = (props, state) => {
    if (props.accountOverviewDetails !== state.accountOverviewDetails) {
      let changedLabelWidth = document.getElementsByClassName('highcharts-point highcharts-color-0') && document.getElementsByClassName('highcharts-point highcharts-color-0').length && document.getElementsByClassName('highcharts-point highcharts-color-0')[0].width && document.getElementsByClassName('highcharts-point highcharts-color-0')[0].width.baseVal ? document.getElementsByClassName('highcharts-point highcharts-color-0')[0].width.baseVal.value : 12;
      let containerWidth = document.getElementsByClassName('highcharts-plot-background') && document.getElementsByClassName('highcharts-plot-background').length && document.getElementsByClassName('highcharts-plot-background')[0].width && document.getElementsByClassName('highcharts-plot-background')[0].width.baseVal ? document.getElementsByClassName('highcharts-plot-background')[0].width.baseVal.value : 486;
      return {
        accountOverviewDetails: props.accountOverviewDetails,
        changedLabelWidth: changedLabelWidth,
        containerWidth: containerWidth
      };
    }
    return null;
  }

  handleResize = () => {
    let changedLabelWidth =
      document.getElementsByClassName('highcharts-point highcharts-color-0') &&
        document.getElementsByClassName('highcharts-point highcharts-color-0')
          .length &&
        document.getElementsByClassName('highcharts-point highcharts-color-0')[0]
          .width &&
        document.getElementsByClassName('highcharts-point highcharts-color-0')[0]
          .width.baseVal
        ? document.getElementsByClassName(
          'highcharts-point highcharts-color-0'
        )[0].width.baseVal.value
        : 12;
    let containerWidth =
      document.getElementsByClassName('highcharts-plot-background') &&
        document.getElementsByClassName('highcharts-plot-background').length &&
        document.getElementsByClassName('highcharts-plot-background')[0].width &&
        document.getElementsByClassName('highcharts-plot-background')[0].width
          .baseVal
        ? document.getElementsByClassName('highcharts-plot-background')[0].width
          .baseVal.value
        : 0;
    this.setState(
      {
        changedLabelWidth: changedLabelWidth,
        containerWidth: containerWidth
      },
      () => {
        if (window.innerWidth <= 1024) {
          this.setState({
            showStackLabels: false
          });
        } else {
          this.setState({
            showStackLabels: true
          });
        }
      }
    );
  };
  handleScroll = () => {
    if (document.getElementById("Audience") && window.scrollY < document.getElementById("Audience").offsetTop) {
      this.setState({
        activeMainTab: '0',
      })
    } else if (document.getElementById("Audience") && document.getElementById("Posts") && window.scrollY >= document.getElementById("Audience").offsetTop && window.scrollY < document.getElementById("Posts").offsetTop) {
      this.setState({
        activeMainTab: '1',
      })
    } else if (document.getElementById("Posts") && window.scrollY > document.getElementById("Posts").offsetTop) {
      this.setState({
        activeMainTab: '2',
      })
    }
  }

  handleActiveTab = (tab) => {
    this.setState({
      activeMainTab: tab
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    let searchObj = {};
    let clearFields = true;
    this.props.getCloudTags(searchObj, null, clearFields);
    this.props.getAccountOverview(null, null, clearFields);
    this.clearSubscriptionError();
  }
  toggleModal = (modalKey) => {
    let modals = this.state.modals;
    modals[modalKey] = !modals[modalKey];
    this.setState({
      modals: modals
    });
  }


  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };
  togglePostTab = activePostTab => {
    if (this.state.activePostTab !== activePostTab) {
      this.setState({
        activePostTab
      });
    }
  };
  generateImgUrl = (type, id) => {
    if (type === 'country') {
      return `${process.env.REACT_APP_API_URL}/assets/images/country_flags/${id}`
    } else if (type === 'brand') {
      return `${process.env.REACT_APP_API_URL}/assets/images/brand_logo/${id}`
    } else if (type === 'interest') {
      return `${process.env.REACT_APP_API_URL}/assets/images/interests/${id}`
    }
  }
  calculatePercentage = (type) => {
    if (this.props.accountOverviewDetails && this.props.accountOverviewDetails.user_profile && this.props.accountOverviewDetails.user_profile.stat_history && this.props.accountOverviewDetails.user_profile.stat_history.length) {
      let data = this.props.accountOverviewDetails.user_profile.stat_history;
      let firstMonth = data[data.length - 1][type];
      let lastMonth = (data[data.length - 2] && data[data.length - 2][type]) || 0;
      return isFinite((firstMonth - lastMonth) / lastMonth * 100) ? ((firstMonth - lastMonth) / lastMonth * 100).toFixed(2) + '%' : null;
    }
    return '';
  }


  downloadPdf = (ref) => {
    if (ref === 'app') {
      // let reportId = window.location.pathname.split('/').pop().trim();
      // this.props.downloadReportPdfAction(reportId);
    } else {
      this.props.showLoadingAction('start');
      html2canvas(document.getElementById(ref), {
        removeContainer: true,
        useCORS: true,
        logging: false,
        foreignObjectRendering: ref === 'app',
        ignoreElements: (element) => {
          return (element.id === 'header' || element.id === 'search' || element.id === 'loader' || element.id === 'footer') || (element.className && (element.className.toString().includes('download-icon-button') || element.className.toString().includes('close') || element.className.toString().includes("tooltip-icon")))
        }
      }).then((canvas) => {
        let contentDataURL = canvas.toDataURL('image/png');
        contentDataURL = contentDataURL.split(';').pop().split('base64,').pop();
        let canvasWidth = document.getElementById(ref).scrollWidth;
        let canvasHeight = document.getElementById(ref).scrollHeight;
        let pdf = new jsPDF();

        let pageHeight = pdf.internal.pageSize.getHeight();
        let pageWidth = pdf.internal.pageSize.getWidth();
        let ratio = canvasWidth / canvasHeight;
        let imgHeight = (pageWidth / ratio);
        let heightLeft = imgHeight;
        let position = 0;
        pdf.addImage(contentDataURL, 'PNG', 0, position, pageWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          pdf.addPage();
          pdf.addImage(contentDataURL, 'PNG', 0, position, pageWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        pdf.save("exported.pdf");
        this.props.showLoadingAction('stop');

      }).catch(error => {
        console.log(error);
        this.props.showLoadingAction('stop');
      });
    }
  }
  clearErrorMessage = () => {
    this.props.clearReportsErrorMsg();
  }

  clearSubscriptionError = () => {
    this.props.clearSubscriptionErrorAction();
  }


  render() {
    return (
      <>
        {this.props.subscriptionError ? showErrorMessage(this.props.subscriptionError, this.clearSubscriptionError, 'none') : null}
        {this.props.reportsErrorMessage ?
          showErrorMessage(this.props.reportsErrorMessage, this.clearErrorMessage, 'none')
          : null}
        {
          !this.props.accountOverviewDetails
            ?
            null
            :
            <ReportSection
              state={this.state}
              handleActiveTab={this.handleActiveTab}
              downloadPdf={this.downloadPdf}
              toggleModal={this.toggleModal}
              calculatePercentage={this.calculatePercentage}
              generateImgUrl={this.generateImgUrl}
              toggle={this.toggle}
              togglePostTab={this.togglePostTab}
              {...this.props}
            />
        }
      </>
    )
  }
}
const mapStateToProps = (state) => {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let data = state.reports.accountOverviewDetails && state.reports.accountOverviewDetails.user_profile && state.reports.accountOverviewDetails.user_profile.recent_posts && state.reports.accountOverviewDetails.user_profile.recent_posts.reverse();
  let monthCategories = [];
  if (data) {
    data.map((post, index) => {
      if (post.created) {

        let firstIndex = data.findIndex(item => item.created.split('-')[0] + '-' + item.created.split('-')[1] === post.created.split('-')[0] + '-' + post.created.split('-')[1]);
        let filteredArray = data.filter(item => item.created.split('-')[0] + '-' + item.created.split('-')[1] === post.created.split('-')[0] + '-' + post.created.split('-')[1]);

        let month = months[parseInt(post.created.split('-')[1], 10) - 1] + ', ' + post.created.split('-')[0];
        if (filteredArray.length === 1) {
          if (firstIndex > 0) {
            let prevItem = index > 0 && data[index - 1];
            let currentItem = data[index];
            if (prevItem) {
              if (prevItem.created.split('-')[0] + '-' + prevItem.created.split('-')[1] !== currentItem.created.split('-')[0] + '-' + currentItem.created.split('-')[1]) {
                for (let i = 0; i < 5; i++) {
                  monthCategories.push("")
                }
              }
            }

          }
          return monthCategories.push(month)
        } else {
          if (firstIndex > 0) {
            let prevItem = index > 0 && data[index - 1];
            let currentItem = data[index];
            if (prevItem) {
              if (prevItem.created.split('-')[0] + '-' + prevItem.created.split('-')[1] !== currentItem.created.split('-')[0] + '-' + currentItem.created.split('-')[1]) {
                for (let i = 0; i < 5; i++) {
                  monthCategories.push("")
                }
              }
            }

          }
          if (index === firstIndex + Math.ceil((filteredArray.length) / 2)) {

            return monthCategories.push(month)
          } else {
            return monthCategories.push("")
          }
        }
      }

      return null;
    })
  }

  let recentPostsCommentsData = [];
  let recentPostsLikesData = [];
  if (data) {
    data.map((post, index) => {


      let prevItem = index > 0 && data[index - 1];
      let currentItem = data[index];
      if (prevItem) {
        if (prevItem.created.split('-')[0] + '-' + prevItem.created.split('-')[1] !== currentItem.created.split('-')[0] + '-' + currentItem.created.split('-')[1]) {
          for (let i = 0; i < 5; i++) {
            recentPostsCommentsData.push({
              y: 0,
              month: 0
            });
          }
        }
      }
      return recentPostsCommentsData.push({
        y: post.stat.comments,

        month: moment(post.created).format('MMM DD, YYYY')
      });
    })
  };

  if (data) {
    data.map((post, index) => {

      let prevItem = index > 0 && data[index - 1];
      let currentItem = data[index];
      if (prevItem) {
        if (prevItem.created.split('-')[0] + '-' + prevItem.created.split('-')[1] !== currentItem.created.split('-')[0] + '-' + currentItem.created.split('-')[1]) {
          for (let i = 0; i < 5; i++) {
            recentPostsLikesData.push({
              y: 0,
              month: 0
            });
          }
        }
      }
      return recentPostsLikesData.push({
        y: post.stat.likes,
        month: moment(post.created).format('MMM DD, YYYY')
      });
    })
  };


  return {
    initialvalues: {
      userName: '',
      userNameSplash: '',
    },
    accountOverviewDetails: state.reports.accountOverviewDetails,
    recentPostsCommentsData: recentPostsCommentsData,
    recentPostsLikesData: recentPostsLikesData,
    monthCategories: monthCategories,
    cloudTags: state.reports.cloudTags,
    subscriptionError: state.reports.subscriptionError,
    reportsErrorMessage: state.reports.reportsErrorMessage,


  }
}
export default connect(mapStateToProps, { clearReportsErrorMsg, getAccountOverview, getCloudTags, showLoadingAction, clearSubscriptionErrorAction })(Reports)
