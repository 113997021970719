import React from 'react'
import { Col, Card, CardBody, CardTitle, ListGroup, ListGroupItem, Modal, ModalHeader, UncontrolledTooltip, ModalBody, Table } from 'reactstrap';
import { RenderButton } from '../../../components';
import { capitalize, showTitle, showTooltip } from '../../../actions';
const InfluencerBrandAffinity = (props) => {
  return (
    <Col sm="6">
      <Card className="card-content-custom">
        <CardBody>
          <CardTitle>Influencer brand affinity</CardTitle>
          <ListGroup className="ba-list ch-6">
            {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.brand_affinity ? (props.accountOverviewDetails.user_profile.brand_affinity.map((brand, index) => {
              if (index < 3) {
                return (
                  <ListGroupItem key={index} className="d-flex align-items-center">
                    <div className="img-col-left"><img src={props.generateImgUrl('brand', brand.id)} alt="img" /></div>
                    <div id={`influencer_${brand.name}`} title={showTitle(`influencer_${brand.name}`, brand.name)} className="text-wrap">
                      {capitalize(brand.name)}
                    </div>
                  </ListGroupItem>
                )
              }
              return null;
            })) : <span className="text-center text-grey "><p className="pt-50">No Record Found</p></span>}
          </ListGroup>
          {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.brand_affinity ?
            <div className="bottom">
              <RenderButton color="link" onClick={() => props.toggleModal("influencersBrandAffinityModal")}>View More</RenderButton>
              <Modal id="influencersBrandAffinityModalDownload" isOpen={props.state.modals.influencersBrandAffinityModal} toggle={() => props.toggleModal("influencersBrandAffinityModal")} >
                <ModalHeader toggle={() => props.toggleModal("influencersBrandAffinityModal")}>
                  <span className="tooltip-button-wrap"><span className="text-tooltip">Influencer brand affinity</span>  <span href="#" id="influencersBrandAffinityModal" className="tooltip-icon"></span></span>
                  <UncontrolledTooltip placement="bottom" className="tooltip-content" target="influencersBrandAffinityModal" >
                    {showTooltip('influencersBrandAffinityModal')}
          </UncontrolledTooltip>
                  <RenderButton onClick={() => props.downloadPdf("influencersBrandAffinityModalDownload")} className="download-icon-button mg-left-15 link">Download PDF</RenderButton>
                </ModalHeader>
                <ModalBody>

                  <Table className="modal-table">
                    <thead>
                      <tr>
                        <th colSpan={2}>Brand</th>

                      </tr>
                    </thead>
                    <tbody>
                      {props.accountOverviewDetails && props.accountOverviewDetails.user_profile && props.accountOverviewDetails.user_profile.brand_affinity ? props.accountOverviewDetails.user_profile.brand_affinity.map((affinity, index) => (
                        <tr key={index}>
                          <th colSpan={2} scope="row"><div className="country-text-wrap">
                            <div className="flag-img brand-img"><img src={props.generateImgUrl('brand', affinity.id)} alt="img" /></div>
                            <div className="country-name">{capitalize(affinity.name)}</div>
                          </div></th>
                        </tr>))
                        :
                        <tr>
                          <td align="center" colSpan="2">No Record Found</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                </ModalBody>
              </Modal>
            </div>
            : null}
        </CardBody>
      </Card>
    </Col>
  )
}

export default InfluencerBrandAffinity
